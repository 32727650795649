import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'firstInitial'
})
export class FirstInitialPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) return ''
    return value[0].toUpperCase()
  }
}
