import { Injectable } from '@angular/core'
import { datadogRum } from '@datadog/browser-rum'
import { environment } from 'src/environments/environment'
import { version } from 'package.json'

@Injectable()
export class DatadogService {
  private initialized: boolean = false

  startDatadog() {
    if (this.initialized || !this.canUseDatadog()) {
      return
    }

    datadogRum.init({
      applicationId: environment.datadog.appId,
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      sampleRate: 100,
      trackInteractions: true,
      service: 'cytracom-desktop',
      version: version
    })

    this.initialized = true
  }

  startRecordingSession(login_id: string) {
    if (!this.canUseDatadog()) {
      return
    }

    datadogRum.startSessionReplayRecording()
    datadogRum.setUser({ email: login_id })
  }

  private canUseDatadog() {
    return (
      window.location.href.indexOf('datadog') !== -1 ||
      window.location.hostname === 'desktop.cytracom.net' ||
      window.location.hostname === 'cytra.tel'
    )
  }
}
