import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxsHmrLifeCycle, NgxsHmrSnapshot } from '@ngxs/hmr-plugin'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { Actions, NgxsModule, StateContext, Store } from '@ngxs/store'
import { NgxLinkifyjsModule } from 'ngx-linkifyjs'
import { NgxMaskModule } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr'
import { SOUNDS_ENABLED } from '../../projects/common/services/sounds/sounds.tokens'
import { ApiModule } from '../../projects/system/api/api.module'
import { SessionModule } from '../../projects/system/session/session.module'
import { AppRootComponent } from './app-root.component'
import { WakeModule } from '../../projects/system/wake/wake.module'
import { IntegrationApiModule } from '../../projects/integrations/api/integration-api.module'
import { AvatarModule } from '../../projects/ui/common/avatar/avatar.module'
import { EmptyStateModule } from '../../projects/ui/common/empty-state/empty-state.module'
import { IconsModule } from 'ui'
import { SoundsModule } from '../../projects/common/services/sounds/sounds.module'
import { AppRoutingModule } from './app.routing.module'
import { CytracomSDKModule } from '@cytracom/sdk-angular'
import { QuillModule } from 'ngx-quill'
import { GlobalState } from '../../projects/system/state/globalState'
import { JanusModule } from '../../projects/system/phone/softphone/janus.module'
import { DatadogModule } from 'projects/system/datadog/datadog.module'

export function initializeState(store: Store, actions: Actions) {
  return () => {
    GlobalState.store = store
    GlobalState.actions = actions
  }
}

@NgModule({
  declarations: [AppRootComponent],
  imports: [
    JanusModule.forRoot(),
    ApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    DatadogModule,
    WakeModule.forRoot(),
    AppRoutingModule,
    NgxsModule.forRoot([], { developmentMode: false }),
    SessionModule,
    NgxsStoragePluginModule.forRoot({
      key: ['session', 'extensions', 'users', 'callMode', 'permissions', 'meet']
    }),
    NgxMaskModule.forRoot({ validation: false }),
    NgxLinkifyjsModule.forRoot({
      enableHash: false,
      enableMention: false
    }),
    IntegrationApiModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    AvatarModule,
    EmptyStateModule,
    IconsModule,
    SoundsModule.forRoot(),
    CytracomSDKModule.forRoot(),
    QuillModule.forRoot({})
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeState,
      deps: [Store, Actions]
    },
    {
      provide: SOUNDS_ENABLED,
      useValue: true
    }
  ],
  bootstrap: [AppRootComponent]
})
export class AppModule implements NgxsHmrLifeCycle<NgxsHmrSnapshot> {
  public hmrNgxsStoreOnInit(
    ctx: StateContext<NgxsHmrSnapshot>,
    snapshot: Partial<NgxsHmrSnapshot>
  ) {
    ctx.patchState(snapshot)
  }

  public hmrNgxsStoreBeforeOnDestroy(
    ctx: StateContext<NgxsHmrSnapshot>
  ): Partial<NgxsHmrSnapshot> {
    return ctx.getState()
  }
}
