import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { hmr } from '@ngxs/hmr-plugin'
import { displayVersion } from './version'
import { register } from './serviceWorker'
import { UpdateService } from '../projects/system/updates/update.service'
import { useApiConfig, useCallHistory } from '@cytracom/desktop'

const win = (window || {}) as any

displayVersion()
enableProdMode()
register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      const listener = (event: any) => {
        if (event.target.state === 'activated') {
          UpdateService.updateAvailable.next(true)
          console.log('New Version Available. Refresh to Update!')
          waitingServiceWorker.removeEventListener('statechange', listener)
        }
      }
      waitingServiceWorker.addEventListener('statechange', listener)
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
    }
  }
})
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)

// provide empty env data since we are not in react or storybook
win.process = win.process || { env: {} }

// Load data from local storage into zustand states
const session = JSON.parse(localStorage.getItem('session') || '{}')
const newCalls = JSON.parse(localStorage.getItem('newCalls') || '[]')
const callsByLinkedId = JSON.parse(
  sessionStorage.getItem('callHistory') || '{}'
)
useApiConfig.setState({
  ...useApiConfig.getState(),
  baseUrl: environment.backend,
  token: session?.app_token || '',
  contactBaseUrl: environment.contactBaseUrl + '/apps/api'
})

useCallHistory.setState({
  ...useCallHistory.getState(),
  callsByLinkedId,
  newCalls
})

if (environment.hmr) {
  hmr(module, bootstrap).catch((err: Error) => console.error(err))
} else {
  bootstrap().catch((err: Error) => console.log(err))
}
