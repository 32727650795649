import { NgModule } from '@angular/core'
import { MatRippleModule } from '@angular/material/core'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatListModule } from '@angular/material/list'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatChipsModule } from '@angular/material/chips'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCardModule } from '@angular/material/card'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatBadgeModule } from '@angular/material/badge'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { CytracomSDKModule } from '@cytracom/sdk-angular'
import { NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

export const modules = [
  CommonModule,
  MatRippleModule,
  MatButtonModule,
  MatMenuModule,
  MatSelectModule,
  FormsModule,
  ReactiveFormsModule,
  MatListModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatTabsModule,
  MatCardModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatBadgeModule,
  MatToolbarModule,
  MatTooltipModule,
  MatButtonToggleModule,
  CytracomSDKModule,
  NgbTooltipModule
]

@NgModule({
  imports: modules,
  exports: modules,
  providers: [NgbTooltipConfig]
})
export class UiModule {
  constructor(config: NgbTooltipConfig) {
    config.placement = [
      'bottom',
      'bottom-right',
      'bottom-left',
      'top',
      'top-left',
      'top-right'
    ]
    config.container = 'body'
  }
}
