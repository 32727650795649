import { NgModule } from '@angular/core'
import { JanusSoftphoneAdapter } from './janus-softphone.adapter'
import { JanusComponent } from './janus.component'
import { ElectronModule } from 'projects/system/electron/electron.module'
import {
  ENVIRONMENT_TOKEN,
  JanusService,
  SIPEnvironmentInformation
} from './janus.service'
import { environment } from 'src/environments/environment'

@NgModule({
  imports: [ElectronModule],
  providers: [],
  declarations: [JanusComponent],
  exports: [JanusComponent]
})
export class JanusModule {
  static forRoot() {
    return {
      ngModule: JanusModule,
      providers: [
        JanusSoftphoneAdapter,
        JanusService,
        {
          provide: ENVIRONMENT_TOKEN,
          useFactory: (): SIPEnvironmentInformation => ({
            standaloneSIP: environment.sip,
            standaloneRegistrar: environment.register,
            activeActiveConnections: environment.activeActiveConnections
          })
        }
      ]
    }
  }
}
