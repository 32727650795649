import { Action, createSelector, State, StateContext } from '@ngxs/store'
import { MessagingPresence } from './messaging-presence.models'
import {
  StoppedTyping,
  StoppedViewing,
  Typing,
  Viewing
} from './messaging-presence.actions'
import { Observable, Subject } from 'rxjs'
import { filter, first } from 'rxjs/operators'
import { Injectable } from '@angular/core'

@State<MessagingPresence>({
  name: 'messagingPresence',
  defaults: new MessagingPresence()
})
@Injectable()
export class MessagingPresenceState {
  static prioritized(threadId: string, alphabetical?: boolean) {
    return createSelector(
      [MessagingPresenceState],
      (state: MessagingPresence) => {
        if (!state.threads[threadId]) {
          return { typing: [], viewing: [] }
        }

        const compare = (a: string, b: string, field: 'typing' | 'viewing') => {
          if (alphabetical) {
            if (a > b) {
              return 1
            }

            if (b > a) {
              return -1
            }

            return 0
          }

          return (
            state.threads[threadId][field][b] -
            state.threads[threadId][field][a]
          )
        }

        const typing = Object.keys(state.threads[threadId].typing).sort(
          (a, b) => compare(a, b, 'typing')
        )
        const viewing = Object.keys(state.threads[threadId].viewing).sort(
          (a, b) => compare(a, b, 'viewing')
        )

        return { typing, viewing }
      }
    )
  }

  static byThreadID(threadId: string) {
    return createSelector(
      [MessagingPresenceState],
      (state: MessagingPresence) => {
        if (!state.threads[threadId]) {
          return { typing: [], viewing: [] }
        }
        return state.threads[threadId]
      }
    )
  }

  static threadIsActive(threadId: string) {
    return createSelector(
      [MessagingPresenceState],
      (state: MessagingPresence) => {
        return Object.keys(state.threads[threadId]?.typing || {}).length > 0
      }
    )
  }

  private static updateState(
    state: MessagingPresence,
    threadId: string,
    field: 'typing' | 'viewing',
    email: string,
    timestamp: number
  ) {
    state.threads[threadId] = {
      ...state.threads[threadId],
      [field]: {
        ...state.threads[threadId][field],
        [email]: timestamp
      }
    }
  }

  private static onStarted(
    context: StateContext<MessagingPresence>,
    action: Viewing,
    field: 'typing' | 'viewing'
  ) {
    const state = MessagingPresenceState.getState(context, action)
    MessagingPresenceState.updateState(
      state,
      action.threadId,
      field,
      action.email,
      new Date().valueOf()
    )
    context.patchState({
      threads: {
        [action.threadId]: state.threads[action.threadId]
      }
    })
  }

  private static getState(
    context: StateContext<MessagingPresence>,
    action: Viewing
  ) {
    const state = { threads: { ...context.getState().threads } }
    if (!state.threads[action.threadId]) {
      state.threads[action.threadId] = { typing: {}, viewing: {} }
    }
    return state
  }

  private static onStop(
    context: StateContext<MessagingPresence>,
    action: StoppedViewing | StoppedTyping,
    field: 'typing' | 'viewing'
  ) {
    const state = MessagingPresenceState.getState(context, action)
    delete state.threads[action.threadId][field][action.email]
    context.patchState({
      threads: {
        [action.threadId]: state.threads[action.threadId]
      }
    })
  }

  typed = new Subject<Typing>()
  viewed = new Subject<Viewing>()

  handleStop(
    event: Observable<Typing | Viewing>,
    action: Typing | Viewing,
    cb
  ) {
    const timeout = setTimeout(() => {
      cb()
      stop?.unsubscribe()
    }, 7 * 1000)

    const stop = event
      .pipe(
        filter(
          (act) =>
            act &&
            action.threadId === act.threadId &&
            action.email === act.email
        ),
        first()
      )
      .subscribe(() => {
        clearTimeout(timeout)
      })
  }

  @Action(Typing)
  onTyping(context: StateContext<MessagingPresence>, action: Typing) {
    this.typed.next(action)
    MessagingPresenceState.onStarted(context, action, 'typing')
    this.handleStop(this.typed, action, () =>
      context.dispatch(new StoppedTyping(action.threadId, action.email))
    )
  }

  @Action(Viewing)
  onViewing(context: StateContext<MessagingPresence>, action: Viewing) {
    this.viewed.next(action)
    MessagingPresenceState.onStarted(context, action, 'viewing')
    this.handleStop(this.viewed, action, () =>
      context.dispatch(new StoppedViewing(action.threadId, action.email))
    )
  }

  @Action(StoppedTyping)
  onStoppedTyping(
    context: StateContext<MessagingPresence>,
    action: StoppedTyping
  ) {
    MessagingPresenceState.onStop(context, action, 'typing')
  }

  @Action(StoppedViewing)
  onStoppedViewing(
    context: StateContext<MessagingPresence>,
    action: StoppedViewing
  ) {
    MessagingPresenceState.onStop(context, action, 'viewing')
  }
}
