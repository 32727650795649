import { Injectable } from '@angular/core'
import { LegacyElectronService } from './legacy-electron.service'
import { BridgedElectronWindow } from './electron.types'
import { ElectronStrategy } from './strategy'
import { BridgedStrategy } from './strategy/BridgedStrategy'
import { LegacyStrategy } from './strategy/LegacyStrategy'

declare let window: Partial<BridgedElectronWindow>

export const PLATFORM_WINDOWS = 'win32'
export const PLATFORM_MACOS = 'darwin'
export const PLATFORM_LINUX = 'linux'

@Injectable()
export class ElectronService {
  constructor(private legacyService: LegacyElectronService) {}

  isRunningInElectron(): boolean {
    return !!window.navigator.userAgent.match(/Electron/)
  }

  isOSOnDND(): Promise<boolean> {
    return this.getStrategy().isOSOnDND()
  }

  updateBadge(count: number): void {
    return this.getStrategy().updateBadge(count)
  }

  sendNotification(
    instance: number,
    config: { title: string } & NotificationOptions
  ): Promise<string> {
    return this.getStrategy().sendNotification(instance, config)
  }

  on(event: string, callback: (...args: any[]) => void): () => void {
    return this.getStrategy().on(event, callback)
  }

  getPlatform(): string {
    return this.getStrategy().getPlatform()
  }

  openWindowsMicPage(): void {
    return this.getStrategy().openWindowsMicPage()
  }

  private getStrategy(): ElectronStrategy {
    if (this.hasBridgedElectronAPI()) {
      return new BridgedStrategy()
    } else {
      return new LegacyStrategy(this.legacyService)
    }
  }

  private hasBridgedElectronAPI(): boolean {
    return window.electronBridge !== undefined
  }
}
