import { NgModule } from '@angular/core'
import { NotificationService } from './notification.service'
import { NotificationComponent } from './notification.component'
import { ToastrModule } from 'ngx-toastr'
import { WebNotificationService } from './web-notification.service'
import { VisibilityModule } from '../../common/services/visibility.module'
import { SoundsModule } from '../../common/services/sounds/sounds.module'
import { UiModule } from 'ui'
import { CollapseWhitespacePipeModule } from '../../common/pipes/collapse-whitespace-pipe.module'
import { PresenceModule } from '../presence/presence.module'
import { ElectronModule } from '../electron/electron.module'

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    UiModule,
    ElectronModule,
    VisibilityModule,
    ToastrModule,
    SoundsModule,
    CollapseWhitespacePipeModule,
    PresenceModule
  ],
  providers: [NotificationService, WebNotificationService],
  entryComponents: [NotificationComponent]
})
export class NotificationModule {}
