import { environment } from '../../../src/environments/environment'
import { encodeAppToken } from '../../common/functions/encode-app-token'
import { Observable, Subject, merge } from 'rxjs'
import { filter, first, map, takeUntil } from 'rxjs/operators'
import { version } from '../../../package.json'
import { ConversationEvents } from '../conversations/events'
import {
  WEBSOCKET_EVENT_TYPES,
  WebsocketConnection,
  WebsocketEvent,
  WebsocketMessageEvent
} from './websocket.connection'

export class EvhubConnection {
  static readonly DEFAULT_SUBSCRIPTIONS: string[] = [
    'vm.#',
    'mwi.#',
    'cdr.#',
    'fax.#',
    'chan.#',
    'queue.#',
    'exten.#',
    'park.#',
    'queue_agent.#',
    'dnd.update',
    'sip_auth',
    ConversationEvents.NUMBER_UPDATE,
    ConversationEvents.MUTED,
    ConversationEvents.UNMUTED,
    ConversationEvents.NUMBER_MUTED,
    ConversationEvents.NUMBER_UNMUTED,
    ConversationEvents.LAST_MESSAGE_TIME,
    ConversationEvents.MESSAGE_RECEIVED,
    ConversationEvents.SHARED_MESSAGE_RECEIVED,
    ConversationEvents.MESSAGE_DELIVERED,
    ConversationEvents.MESSAGE_EDITED,
    ConversationEvents.MESSAGES_DELETED,
    ConversationEvents.HIDDEN,
    ConversationEvents.RENAMED,
    ConversationEvents.ROOM_MEMBERS_ADDED,
    ConversationEvents.ROOM_MEMBERS_REMOVED,
    ConversationEvents.ROOM_CREATED,
    ConversationEvents.ROOM_MODIFIED,
    ConversationEvents.ROOM_LEFT,
    ConversationEvents.ROOM_ARCHIVED,
    ConversationEvents.ROOM_UNARCHIVED
  ]

  events: Subject<{ type: string } & any>
  private activeSubscriptions: Set<string>
  private stop: Subject<string>

  constructor(private socketConnection: WebsocketConnection) {
    this.activeSubscriptions = new Set(EvhubConnection.DEFAULT_SUBSCRIPTIONS)
    this.stop = new Subject()
    this.events = new Subject()

    this.setupEventHandlers()
  }

  static getUrl(token: string) {
    return (
      environment.websocket +
      '/ws/subscribe/' +
      encodeAppToken(token) +
      '?cdesktop-ver=' +
      version
    )
  }

  disconnect() {
    this.socketConnection.disconnect()
  }

  connect(url?: string) {
    return this.socketConnection.connect(url)
  }

  off(event: string) {
    this.stop.next(event)
  }

  on$(eventType: string) {
    // As part of a refactor, I made "message" event types distinct. However, all of the callers of this mix usecases
    // and I don't want to risk breaking them, so I am "merging" usecases here.
    const messageObservable = this.socketConnection.on$('message').pipe(
      filter((event: WebsocketMessageEvent) => {
        const { data: eventData } = event

        const eventNames = [
          eventData?.type,
          eventData?.event,
          eventData?.event?.event
        ]
        const matchingEvent = eventNames.find(
          (field) => typeof field === 'string' && field.indexOf(eventType) > -1
        )

        return matchingEvent !== undefined
      }),
      map(({ data }) => data),
      takeUntil(this.stop.pipe(filter((name) => name === eventType)))
    )

    if (
      !WEBSOCKET_EVENT_TYPES.find((allowedType) => allowedType === eventType)
    ) {
      return messageObservable
    }

    // We have just asserted above that the event is contained within the set of allowed types, this cast is safe.
    const providedTypeObservable = this.socketConnection
      .on$(eventType as WebsocketEvent['type'])
      .pipe(takeUntil(this.stop.pipe(filter((name) => name === eventType))))

    return merge(messageObservable, providedTypeObservable)
  }

  on(event: string, cb: (...args: any[]) => void, once = false) {
    if (once) {
      this.on$(event).pipe(first()).subscribe(cb)
      return
    }

    this.on$(event).subscribe(cb)
  }

  subscribe(
    event: string,
    cb: (...args: any[]) => void,
    once = false,
    unsubscribe?: Observable<unknown>
  ) {
    this.on(event, cb, once)
    if (unsubscribe == null) {
      return
    }

    const sub = unsubscribe.subscribe(() => {
      this.off(event)
      sub.unsubscribe()
    })
  }

  once(event: string, cb: (...args: any[]) => void) {
    this.on(event, cb, true)
  }

  emit(
    event: string,
    data: { [key: string]: any } = {},
    id?: string
  ): Promise<void> {
    if (event === 'subscribe') {
      // Immediately change our set of "active" subscriptions. Technically evhub will send a subscribe.ack,
      // but there's no reason to wait on this; if we lose the socket connection, we want to preserve
      // our existing list of subscriptions
      this.updateSubscriptions(data.add ?? [], data.remove ?? [])
    }

    return this.socketConnection.emit(event, data, id)
  }

  private setupEventHandlers() {
    this.on('open', () => {
      const subscriptions = this.activeSubscriptions.size
        ? [...this.activeSubscriptions.values()]
        : EvhubConnection.DEFAULT_SUBSCRIPTIONS

      if (subscriptions.length) {
        this.emit('subscribe', {
          add: subscriptions
        })
      }
    })
  }

  private updateSubscriptions(
    addSubscriptions: string[],
    removeSubscriptions: string[]
  ) {
    addSubscriptions.forEach((subscription: string) => {
      this.activeSubscriptions.add(subscription)
    })

    removeSubscriptions.forEach((subscription: string) => {
      this.activeSubscriptions.delete(subscription)
    })
  }
}
