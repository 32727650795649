import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  OnInit
} from '@angular/core'
import { Subject } from 'rxjs'
import { async } from 'rxjs/internal/scheduler/async'
import { takeUntil, throttleTime } from 'rxjs/operators'
import { NotificationService } from '../../notifications/notification.service'
import { JanusSoftphoneAdapter } from './janus-softphone.adapter'
import { JanusServerInfo } from '@cytracom/softphone/softphone/janus/connect/JanusConnector'
import { JanusService } from './janus.service'

@Component({
  selector: 'janus',
  template: '<div #element></div>'
})
export class JanusComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('element')
  element: ElementRef<HTMLDivElement>

  @Output()
  changeCallMode = new EventEmitter()

  destroy = new Subject()

  constructor(
    private adapter: JanusSoftphoneAdapter,
    private service: JanusService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    const inputDevice = localStorage.getItem('audio.input') ?? 'default'
    this.adapter.changeInput(inputDevice)
    const outputDevice = localStorage.getItem('audio.output') ?? 'default'
    this.adapter.changeOutput(outputDevice)
  }

  ngAfterViewInit() {
    this.adapter.onChangeCallMode = () => this.changeCallMode.emit()
    this.initialize()
    this.adapter.displayNetworkError
      .pipe(takeUntil(this.destroy))
      .pipe(throttleTime(1000, async, { leading: true, trailing: true }))
      .subscribe(() =>
        this.notification.snack(
          'Connectivity issues are detected. Please check your network connection.'
        )
      )
  }

  ngOnDestroy() {
    this.adapter.onDestroy()
  }

  private initialize() {
    let initialized = false

    this.service.servers$().forEach((serverInfo: JanusServerInfo[]) => {
      if (!initialized) {
        this.adapter.onInit(this.element.nativeElement, serverInfo)
        initialized = true
      } else {
        this.adapter.updateCredentials(serverInfo)
      }
    })
  }
}
