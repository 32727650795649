import { Pipe, PipeTransform } from '@angular/core'
import { PresenceUser } from 'projects/system/presence/state/presence.model'

export type UserStatus = 'online' | 'offline' | 'dnd'

/**
 * Converts the presence of a user to a status string that can be used by the status component
 */
@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  constructor() {}
  transform(presenceUser: PresenceUser): UserStatus {
    if (presenceUser.online && presenceUser.do_not_disturb) {
      return 'dnd'
    } else if (presenceUser.online) {
      return 'online'
    } else {
      return 'offline'
    }
  }
}
