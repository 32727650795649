import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'collapseWhitespace'
})
export class CollapseWhitespacePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return ''
    }

    return value.replace(/\s\s+/g, ' ')
  }
}
