import { InitialPresenceState } from './presence.model'

export namespace Presence {
  export namespace Online {
    export class Load {
      static readonly type = '[Presence; online] Load'

      constructor(public state: InitialPresenceState) {}
    }

    export class Update {
      static readonly type = '[Presence; online] Update'

      constructor(public user: string, public online: boolean) {}
    }
  }

  export namespace DND {
    export class Load {
      static readonly type = '[Presence; dnd] Load'

      constructor(public state: InitialPresenceState) {}
    }

    export class Update {
      static readonly type = '[Presence; dnd] Update'

      constructor(public user: string, public do_not_disturb: boolean) {}
    }
  }
}
