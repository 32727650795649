import { NgModule } from '@angular/core'
import { StatusLabelPipe } from './label.pipe'
import { StatusComponent } from './status.component'
import { StatusPipe } from './status.pipe'

@NgModule({
  imports: [],
  declarations: [StatusComponent, StatusPipe, StatusLabelPipe],
  exports: [StatusComponent, StatusPipe, StatusLabelPipe]
})
export class StatusModule {}
