import { HttpParameterCodec } from '@angular/common/http'

export class PassThroughCodec implements HttpParameterCodec {
  decodeKey(key: string) {
    return key
  }

  decodeValue(value: string) {
    return value
  }

  encodeKey(key: string) {
    return key
  }

  encodeValue(value: string) {
    return value
  }
}
