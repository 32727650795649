export enum ConnectionHealth {
  DEAD,
  CONNECTING,
  HEALTHY
}

interface WatchdogOptions {
  getHealth: () => ConnectionHealth
  reconnect: () => Promise<void>
  checkIntervalMS?: number
}

// Normally I'd suggest doing ReturnType<typeof window.setInterval> but in the environment Jest runs in,
// this fails to compile, and I can't find a clear solution quickly enough for it to matter.
type Timer = number

/**
 * The Watchdog is a failsafe for a connection, to ensure that we are always connected. Generally, the watchdog
 * should not be relied upon (there are more graceful error handling mechanisms), but should we be dead in in the water,
 * the watchdog will reconcile the connection.
 */
export class Watchdog {
  private readonly getHealth: () => ConnectionHealth
  private readonly reconnect: () => Promise<void>
  private readonly checkIntervalMS: number
  private intervalTimer: Timer | null

  constructor(watchdogOptions: WatchdogOptions) {
    this.intervalTimer = null
    this.getHealth = watchdogOptions.getHealth
    this.reconnect = watchdogOptions.reconnect
    this.checkIntervalMS = watchdogOptions.checkIntervalMS ?? 30_000
  }

  start() {
    if (this.intervalTimer != null) {
      return
    }

    this.intervalTimer = window.setInterval(
      () => this.checkConnection(),
      this.checkIntervalMS
    )
  }

  stop() {
    if (this.intervalTimer == null) {
      return
    }

    window.clearInterval(this.intervalTimer)
    this.intervalTimer = null
  }

  private checkConnection() {
    const health = this.getHealth()
    if (health === ConnectionHealth.DEAD) {
      console.warn('Watchdog encountered a dead connection, reconnecting...')
      this.reconnect()
    }
  }
}
