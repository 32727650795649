import { default as mixpanel } from 'mixpanel-browser'
import { version } from '../../../package.json'
import { useTracking } from '@cytracom/tracking'

const environments = {
  'desktop.cytracom.net': 'prod',
  'cytra.tel': 'prod',
  'dev-i433232.cytracom.net': 'cdeb',
  'beta-desktop.cytracom.net': 'beta',
  'beta-dev-i433232.cytracom.net': 'beta-dev'
}

export const config = {
  environment:
    window.location.hostname in environments
      ? environments[window.location.hostname]
      : 'dev',
  getSourcePage: () => '',
  navigationEvents: ['navigation.menu', 'navigation.dialpad'],
  token: '080f096da2c3f2ab285ed97e4235ff9e',
  isElectron: false
}

let initialized = false
const init = () => {
  mixpanel.init(config.token, { persistence: 'localStorage' })
  initialized = true
}

export const tracking = {
  track: (
    name: string,
    event: { [key: string]: string | number | boolean } = {}
  ) => {
    if (!initialized) {
      init()
    }

    event.AppVersion = version
    event.environment = config.isElectron
      ? config.environment + '-electron'
      : config.environment
    if (config.navigationEvents.includes(name))
      event['navigation.source.page'] = config.getSourcePage()
    mixpanel.track(name, event)
  },
  identify: (data: any) => mixpanel.identify(data),
  init,
  // Null so that during module loading, this won't be set to an undefined property from mixpanel
  // (unless init has been called this won't be set). This is later fixed by the Object.defineProperty below.
  people: null as any
}

Object.defineProperty(tracking, 'people', {
  get: () => mixpanel.people
})

useTracking.getState().setTrackingFunction(tracking.track)
