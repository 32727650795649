import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { SessionState } from './state/session.state'
import { RouterModule } from '@angular/router'
import { SessionGuard } from './session.guard'
import { PermissionState } from './state/permission.state'

@NgModule({
  providers: [SessionGuard],
  imports: [
    RouterModule,
    NgxsModule.forFeature([SessionState, PermissionState])
  ]
})
export class SessionModule {}
