import { ModuleWithProviders, NgModule } from '@angular/core'
import { WakeService } from './wake.service'
import { NgxsModule } from '@ngxs/store'

@NgModule({
  imports: [NgxsModule]
})
export class WakeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WakeModule,
      providers: [WakeService]
    }
  }
}
