import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core'
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs'
import { PushComponent } from '../../../common/classes/push.component'
import { MessagingPresenceState } from '../../../system/conversations/conversation-history/state/messaging-presence/messaging-presence.state'
import { findContact, getContactData, useUserStatus } from '@cytracom/desktop'
import { SessionState } from '../../../system/session/state/session.state'
import { SessionData } from '../../../page/login/session.data'

let instance = 0

export interface AvatarState {
  hasPresence?: boolean
  hasUser?: boolean
  online: boolean
  dnd: boolean
  avatar: string
  typing?: boolean
}

@Component({
  selector: 'avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss'],
  inputs: [
    'color',
    'label',
    'size',
    'status',
    'noPresence',
    'noStatus',
    'threadId',
    'showPerson',
    'cutout',
    'radius'
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent
  extends PushComponent<AvatarState>
  implements OnDestroy
{
  showPerson = false
  cutout = [0, 0]
  radius = 10

  unsubscribe = () => {}

  get threadId(): string {
    return this._threadId
  }

  set threadId(value: string) {
    this._threadId = value
    this.threadSubscription?.unsubscribe()
    if (value) {
      this.threadSubscription = this.store
        .select(MessagingPresenceState.prioritized(value))
        .subscribe((state) => {
          if (this.state.typing && !state.typing.length) {
            this.patchState({ typing: false })
          }
          if (!this.state.typing && state.typing.length) {
            this.patchState({ typing: true })
          }
        })
    }
  }

  get value(): string {
    return this._value
  }

  @Input()
  set value(value: string) {
    this._value = value
    if (!value) {
      return
    }

    const { login_id, avatar_url } =
      this.store.selectSnapshot<SessionData>(SessionState)

    findContact(value).then(({ contact }) => {
      if (contact) {
        const { email, source } = getContactData(contact)
        if (source === 'extension') {
          const presence = useUserStatus.getState().presence[email]
          this.patchState({
            hasPresence: typeof presence !== 'undefined',
            online: presence?.online || false,
            dnd: presence?.do_not_disturb || false
          })
        }
      }

      if (value === login_id) {
        this.patchState({
          hasUser: true,
          avatar: avatar_url || ''
        })
        return
      }

      this.patchState({
        hasUser: !!contact,
        avatar: contact ? getContactData(contact).avatar : ''
      })
    })

    const unsubscribeUserStatus = useUserStatus.subscribe(
      ({ presence }) => presence,
      (presences) => {
        findContact(value).then(({ contact }) => {
          if (!contact) return
          const { email, source } = getContactData(contact)
          if (source === 'extension') {
            const presence = presences[email]

            this.patchState({
              hasPresence: typeof presence !== 'undefined',
              online: presence?.online || false,
              dnd: presence?.do_not_disturb || false
            })
          }
        })
      }
    )

    this.unsubscribe = () => {
      unsubscribeUserStatus()
    }
  }

  instance = ++instance
  label = ''
  private _value = ''
  color = ''
  status: 'off-hook' | 'ringing' | '' = ''
  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  size = 40

  private _threadId = ''

  colorMap = {
    green: 'lime',
    blue: 'blue',
    lime: 'lime',
    black: 'black',
    'line-1': 'seafoam-green',
    'line-2': 'royal-blue',
    'line-3': 'pastel-purple',
    'line-4': 'teal'
  }

  noStatus = false
  noPresence = false

  threadSubscription: Subscription

  constructor(private store: Store, changes: ChangeDetectorRef) {
    super(changes, {
      online: false,
      avatar: null,
      dnd: false
    })
  }

  ngOnDestroy() {
    this.threadSubscription?.unsubscribe()
    this.unsubscribe()
  }
}
