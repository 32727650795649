import { NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { SessionModule } from '../session/session.module'
import { NgxsModule } from '@ngxs/store'
import { ApiInterceptor } from './api.interceptor'
import { NotificationModule } from '../notifications/notification.module'

@NgModule({
  imports: [NgxsModule, NotificationModule, HttpClientModule, SessionModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ApiInterceptor
    }
  ],
  exports: [HttpClientModule]
})
export class ApiModule {}
