import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../src/environments/environment'
import {
  AppVote,
  IntegrationApp,
  MarketplaceApp
} from './integration.api.models'

@Injectable()
export class IntegrationApi {
  get domain() {
    return environment.ihub.indexOf('http') === 0 ? environment.ihub : ''
  }

  constructor(private http: HttpClient) {}

  async storeData(appId: string, data: { [key: string]: string }) {
    return await this.http
      .post(
        this.domain +
          '/storage/app/' +
          encodeURIComponent(appId) +
          '?app_token={token}',
        { data }
      )
      .toPromise()
  }

  async getData(appId: string) {
    return await this.http
      .get<{ [key: string]: string }>(
        this.domain +
          '/storage/app/' +
          encodeURIComponent(appId) +
          '?app_token={token}'
      )
      .toPromise()
  }

  async getEnabledApps() {
    return await this.http
      .get<{ apps: IntegrationApp[] }>(
        this.domain + '/apps/loader?app_token={token}'
      )
      .toPromise()
  }

  async getEnabledApp(appId: string) {
    return await this.http
      .get<{ app: IntegrationApp }>(
        this.domain +
          '/apps/loader/' +
          encodeURIComponent(appId) +
          '?app_token={token}'
      )
      .toPromise()
  }

  async enableApp(appId: string) {
    return await this.http
      .post<{ res: boolean }>(
        this.domain +
          '/apps/loader/' +
          encodeURIComponent(appId) +
          '/enable?app_token={token}',
        null
      )
      .toPromise()
  }

  async disableApp(appId: string) {
    return await this.http
      .post<{ res: boolean }>(
        this.domain +
          '/apps/loader/' +
          encodeURIComponent(appId) +
          '/disable?app_token={token}',
        null
      )
      .toPromise()
  }

  async getApps(filter?: string) {
    if (!!filter && filter.length > 2) {
      return await this.http
        .get<MarketplaceApp[]>(
          this.domain +
            '/apps/marketplace?app_token={token}&from=0&size=1000&searchFilter=' +
            encodeURIComponent(filter)
        )
        .toPromise()
    }
    return await this.http
      .get<MarketplaceApp[]>(
        this.domain + '/apps/marketplace?app_token={token}&from=0&size=1000'
      )
      .toPromise()
  }

  async getAppById(appId: string) {
    return await this.http
      .get<MarketplaceApp>(
        this.domain +
          '/apps/marketplace/' +
          encodeURIComponent(appId) +
          '?app_token={token}'
      )
      .toPromise()
  }

  async getVotes(appIds?: string[]) {
    if (!!appIds && appIds.length > 0) {
      return await this.http
        .post<{ appVotes: AppVote[] }>(
          this.domain + '/apps/ratings?app_token={token}',
          { appIds }
        )
        .toPromise()
    }
    return await this.http
      .post<{ appVotes: AppVote[] }>(
        this.domain + '/apps/ratings?app_token={token}',
        null
      )
      .toPromise()
  }

  async upVote(appId: string) {
    await this.http
      .post(
        this.domain +
          '/apps/ratings/' +
          encodeURIComponent(appId) +
          '/vote?app_token={token}',
        null
      )
      .toPromise()
  }

  async downVote(appId: string) {
    await this.http
      .post(
        this.domain +
          '/apps/ratings/' +
          encodeURIComponent(appId) +
          '/unvote?app_token={token}',
        null
      )
      .toPromise()
  }
}
