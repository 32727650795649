import { Observable, Subject } from 'rxjs'
import { ConnectionHealth, Watchdog } from './watchdog'
import { v4 as uuid } from 'uuid'
import { filter, takeUntil } from 'rxjs/operators'
import { assert, Equals } from 'tsafe'
import { BackoffGenerator } from '../common/backoff'

export interface WebsocketMessageEvent {
  type: 'message'
  data: any
}

export interface WebsocketErrorEvent {
  type: 'error'
  error: any
}

export interface WebsocketOpenEvent {
  type: 'open'
}

export interface WebsocketClosedEvent {
  type: 'close'
}

// TODO: Maybe we can consolidate these two with the open/closed
export interface WebsocketOnlineEvent {
  type: 'online'
  connected: boolean
}

export interface WebsocketOfflineEvent {
  type: 'offline'
}

export type WebsocketEvent =
  | WebsocketMessageEvent
  | WebsocketErrorEvent
  | WebsocketOpenEvent
  | WebsocketClosedEvent
  | WebsocketOnlineEvent
  | WebsocketOfflineEvent

export const WEBSOCKET_EVENT_TYPES = [
  'message',
  'error',
  'open',
  'close',
  'online',
  'offline'
] as const

assert<Equals<typeof WEBSOCKET_EVENT_TYPES[number], WebsocketEvent['type']>>(
  true,
  'There is a mismatch between the list of event types and the accepted event types. Please ensure that WEBSOCKET_EVENT_TYPES matches the type signature of WebsocketEvent'
)

// https://stackoverflow.com/a/59681620
type Timer = ReturnType<typeof setInterval>
interface WebSocketTimers {
  ping: Timer | null
  reconnect: Timer | null
}

enum ConnectionStatus {
  /**
   * The initial state of any connection. We could be connected, but likely aren't.
   */
  POSSIBLY_DISCONNECTED,
  /**
   * The connection has been opened, but traffic hasn't been sent.
   */
  OPENED,
  /**
   * Traffic has been sent, we're online.
   */
  PING_SUCCEEDED,
  /**
   * Our ping failed; we're definitely offline
   */
  PING_FAILED
}

class ConnectionFailure extends Error {
  constructor(public event: any) {
    super('Failed to connect to websocket')
  }
}

export class WebsocketConnection {
  private events: Subject<WebsocketEvent>
  private websocket: WebSocket | null

  private url: string

  private timers: WebSocketTimers
  private disconnectingDeliberately: boolean
  private connectionStatus: ConnectionStatus
  private watchdog: Watchdog
  private socketId: number = -1
  private backoffGenerator: BackoffGenerator

  constructor() {
    this.events = new Subject()
    this.websocket = null
    this.timers = { ping: null, reconnect: null }
    this.disconnectingDeliberately = false
    this.connectionStatus = ConnectionStatus.POSSIBLY_DISCONNECTED
    this.backoffGenerator = new BackoffGenerator()
    this.watchdog = new Watchdog({
      checkIntervalMS: 30_000,
      getHealth: () => this.getSocketHealth(),
      reconnect: () => this.reconnect()
    })
  }

  /**
   * Connect to the Websocket URL. This is an alias for reconnect()
   */
  connect(url?: string) {
    return this.reconnect(url)
  }

  /**
   * Disconnect from the websocket
   */
  disconnect() {
    this.watchdog.stop()
    this.destroy()
  }

  on$(eventType: WebsocketEvent['type']): Observable<WebsocketEvent> {
    return this.events.pipe(filter((event) => event.type === eventType))
  }

  /**
   * Reconnect to the websocket URL, even if we are already connected.
   */
  reconnect(url?: string): Promise<void> {
    if (this.url == null && url == null) {
      throw new Error('Attempted to perform a connect but no URL was set.')
    } else if (url != null) {
      this.url = url
    }

    return new Promise<void>((resolve, reject) => {
      if (this.websocket) {
        this.destroy()
      }

      this.watchdog.start()
      // During a reconnect, we can't be disconnecting deliberately
      // This is likely not necessary, but is defensive
      this.disconnectingDeliberately = false
      let connected = false

      this.websocket = new WebSocket(this.url)
      this.socketId++
      this.websocket.onopen = this.socketEventListener(() => {
        if (!connected) {
          connected = true
          resolve()
        }

        this.onOpen()
      })

      this.websocket.onerror = this.socketEventListener((event) => {
        const err = new ConnectionFailure(event)
        if (!connected) {
          reject(err)
        }

        this.onError(err)
      })

      this.websocket.onmessage = this.socketEventListener((e) => {
        this.onMessage(e)
      })

      this.websocket.onclose = this.socketEventListener((e) => {
        this.onClose(e)
      })
    })
  }

  emit(
    event: string,
    data: { [key: string]: any } = {},
    id?: string
  ): Promise<void> {
    return this.performEmit(event, data, id || uuid())
  }

  private async performEmit(
    event: string,
    data: { [key: string]: any } = {},
    id?: string,
    retryCount: number = 0
  ) {
    const retry = (
      retryMessage: string,
      failMessage: string
    ): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (retryCount >= 10) {
          console.warn(failMessage)
          reject(failMessage)
          return
        }

        setTimeout(() => {
          console.warn(retryMessage)
          this.performEmit(event, data, id, retryCount + 1)
            .then(() => resolve())
            .catch(reject)
        }, 1000)
      })
    }

    if (!this.websocket) {
      return retry(
        'Websocket does not exist. Attempting to emit again',
        'Websocket does not exist, and was not automatically created; emit failed'
      )
    }

    switch (this.websocket.readyState) {
      case WebSocket.CLOSED:
        return retry(
          'Websocket is currently closed and cannot reconnect, attempting to emit again',
          'Websocket is currently closed and cannot reconnect; emit failed'
        )
      case WebSocket.CONNECTING:
        return retry(
          'Websocket is connecting, attempting to emit again.',
          'Websocket is taking too long to connect; emit failed'
        )
      case WebSocket.CLOSING:
        return retry(
          'Websocket is closing, attempting to emit again.',
          'Websocket is taking too long to close; emit failed'
        )
    }

    this.websocket.send(
      JSON.stringify({
        id,
        event,
        data
      })
    )
  }

  private reconnectUntilConnected() {
    const reconnectDelay = this.backoffGenerator.nextTimeout()
    const reconnectTimer = setTimeout(() => {
      this.reconnect()
        .then(() => {
          // We've reconnected, so we don't need to keep trying
          // We should do this immediately so we don't try twice
          this.clearTimer('reconnect')
        })
        .catch((err) => {
          if (err instanceof ConnectionFailure) {
            // This specific case comes from the onError of the websocket, but in those cases,
            // onClose will be called, which will perform a reconnect for us, so we shouldn't double-up.
            return
          }

          this.reconnectUntilConnected()
        })
    }, reconnectDelay)

    this.storeTimer(reconnectTimer, 'reconnect')
  }

  /**
   * Wrap an event callback such that events are not emitted if this wrapper has moved to a new raw socket.
   * This is to work around a particularly unfortunate edgecase. Even if we remove the websocket from our
   * state, its old event listeners are still totally active, which can confuse any state that may get set.
   *
   * https://schneide.blog/2021/12/20/the-ever-connecting-websocket/
   *
   * @param callback The callback to call for valid events
   * @returns A wrapped version of `callback`
   */
  private socketEventListener(
    callback: (...args: any[]) => void
  ): (...args: any) => void {
    const currentSocketId = this.socketId

    return (...args: any[]) => {
      // if the socket id has changed, ignore events.
      if (this.socketId === currentSocketId) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(...args)
      }
    }
  }

  private ping() {
    this.emitAndWaitForReply('ping', {}, 5000)
      .then(() => {
        if (this.connectionStatus !== ConnectionStatus.PING_SUCCEEDED) {
          this.connectionStatus = ConnectionStatus.PING_SUCCEEDED
          this.sendOpenEvent()
        }
      })
      .catch(() => {
        if (this.connectionStatus !== ConnectionStatus.PING_FAILED) {
          this.connectionStatus = ConnectionStatus.PING_FAILED
          this.sendCloseEvent()
        }
      })
  }

  private emitAndWaitForReply(
    event: string,
    data: any = {},
    timeoutDuration = 5000
  ): Promise<any> {
    const id = uuid()
    const stop = new Subject()

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject('Timeout')
        stop.next()
      }, timeoutDuration)

      this.on$('message')
        .pipe(
          filter(
            ({ data: messageData }: WebsocketMessageEvent) =>
              messageData.type === 'pong' && messageData.reply_to === id
          ),
          takeUntil(stop)
        )
        .subscribe(({ data: messageData }: WebsocketMessageEvent) => {
          clearTimeout(timeout)
          resolve(messageData)
          stop.next()
        })

      this.emit(event, data, id)
    })
  }

  /**
   * Check if our current disconnect is deliberate.
   *
   * We will also unset the variable to prevent keeping this around.
   */
  private checkAndUnsetDeliberateClose(): boolean {
    const disconnectingDeliberately = this.disconnectingDeliberately
    this.disconnectingDeliberately = false

    return disconnectingDeliberately
  }

  /**
   * Store in our internal state that we're closing the socket deliberately
   */
  private markClosingDeliberately() {
    this.disconnectingDeliberately = true
  }

  private clearAllTimers() {
    for (const key of Object.keys(this.timers)) {
      // We know this to be true, given the object we are iterating over the set of timers
      this.clearTimer(key as keyof WebSocketTimers)
    }
  }

  private clearTimer(intervalType: keyof WebSocketTimers) {
    const currentInterval = this.timers[intervalType]
    if (currentInterval != null) {
      // From MDN (https://developer.mozilla.org/en-US/docs/Web/API/clearTimeout):
      //
      // > It's worth noting that the pool of IDs used by setTimeout() and
      //   setInterval() are shared, which means you can technically use
      //   clearTimeout() and clearInterval() interchangeably.
      clearInterval(currentInterval)
    }

    this.timers[intervalType] = null
  }

  private storeTimer(id: Timer, timerType: keyof WebSocketTimers) {
    // Ideally we shouldn't need this, but we should do this so we don't lose timers
    this.clearTimer(timerType)

    this.timers[timerType] = id
  }

  private onOpen() {
    this.connectionStatus = ConnectionStatus.OPENED
    this.backoffGenerator.reset()
    this.sendOpenEvent()
    this.sendOnlineEvent()

    const pingInterval = setInterval(() => {
      this.ping()
    }, 10 * 1000)
    this.storeTimer(pingInterval, 'ping')

    window.addEventListener('online', this.onOnline)
    window.addEventListener('offline', this.onOffline)
  }

  private onError(error: ConnectionFailure) {
    console.error('Websocket error', error.event)
    this.sendErrorEvent(error)
  }

  // NOTE: onOnline and onOffline must be arrow functions so we can be sure 1) they identify as the same function
  // when we remove their listeners, and 2) so `this` binds properly when they are called.
  private onOnline = async () => {
    // Send an online event, which will indicate our current connection status
    this.sendOnlineEvent()

    // We may or may not be disconnected at this point; we can't really know until we try, so we're "possibly" offline.
    this.connectionStatus = ConnectionStatus.POSSIBLY_DISCONNECTED

    // Now reconcile the connection
    if (this.websocket.readyState === WebSocket.CLOSED) {
      await this.reconnect()
    } else {
      this.ping()
    }
  }

  private onOffline = () => {
    this.sendOfflineEvent()

    if (this.connectionStatus !== ConnectionStatus.POSSIBLY_DISCONNECTED) {
      // We can't know if we're disconnected, because the TCP handle may still be open for the websocket
      this.connectionStatus = ConnectionStatus.POSSIBLY_DISCONNECTED
      this.sendCloseEvent()
    }
  }

  private onMessage(e: MessageEvent) {
    if (!e.data) {
      // events without data aren't important
      return
    }

    const jsonData = (() => {
      try {
        return JSON.parse(e.data)
      } catch (e) {
        console.debug(`Got non-JSON data on websocket: ${e.data}`)
      }
    })()

    this.events.next({ type: 'message', data: jsonData })
  }

  private onClose(e: CloseEvent) {
    console.warn('WebSocket Closed!', e)
    this.sendCloseEvent()

    if (this.checkAndUnsetDeliberateClose()) {
      return
    }

    this.reconnectUntilConnected()
  }

  private sendOnlineEvent() {
    // If we come online, we should set ourselves to "online". This may not technically be true,
    // but we will not get a message on the socket if we go off/online and somehow the TCP
    // connection does persist.
    //
    // If it's somehow true that we are offline in evhub (unlikely but possible),
    // a presence update event should let us know...
    const connected =
      this.connectionStatus === ConnectionStatus.OPENED ||
      this.connectionStatus === ConnectionStatus.PING_SUCCEEDED
    this.events.next({ type: 'online', connected })
  }

  private sendErrorEvent(error: any) {
    this.events.next({ type: 'error', error })
  }

  private sendCloseEvent() {
    this.events.next({ type: 'close' })
  }

  private sendOpenEvent() {
    this.events.next({ type: 'open' })
  }

  private sendOfflineEvent() {
    this.events.next({ type: 'offline' })
  }

  private getSocketHealth(): ConnectionHealth {
    // Check the cases where we know we're 100% in a good or bad state
    if (
      this.websocket == null ||
      this.connectionStatus === ConnectionStatus.PING_FAILED
    ) {
      return ConnectionHealth.DEAD
    } else if (this.connectionStatus === ConnectionStatus.PING_SUCCEEDED) {
      return ConnectionHealth.HEALTHY
    }

    // If that fails, fall back to the actual websocket so we can get some semblance of accuracy
    switch (this.websocket.readyState) {
      case WebSocket.CLOSING:
      case WebSocket.CLOSED:
        return ConnectionHealth.DEAD
      case WebSocket.CONNECTING:
        return ConnectionHealth.CONNECTING
      case WebSocket.OPEN:
        return ConnectionHealth.HEALTHY
    }

    // This should cover all cases but just in case, but in the default case, we will assume it is ok.
    console.warn(
      'Websocket health check entered an indeterminate state; assuming healthy'
    )
    return ConnectionHealth.HEALTHY
  }

  private destroy() {
    this.markClosingDeliberately()
    try {
      window.removeEventListener('online', this.onOnline)
      window.removeEventListener('offline', this.onOffline)
      if (this.websocket) {
        this.sendCloseEvent()
        this.websocket.close()
        this.websocket = null
        this.clearAllTimers()
      }
    } catch (e) {
      console.warn(`Failed to destroy websocket: ${e}`)
    }
  }
}
