import { Component, HostBinding } from '@angular/core'

@Component({
  selector: 'icons',
  templateUrl: 'icons.component.html'
})
export class IconsComponent {
  @HostBinding('style.display')
  display = 'none'
}
