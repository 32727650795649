export const DateOverride = {
  hasChangedOffset: false,
  offset: 0
}
Date = <any>(function (Date) {
  DateWithOffset.prototype = Date.prototype
  DateWithOffset.parse = Date.parse
  DateWithOffset.UTC = Date.UTC
  DateWithOffset.now = Date.now
  return DateWithOffset

  function DateWithOffset(...args: any[]) {
    let date: Date & any
    if (args.length) {
      date = new (Date as any)(...args)
    } else {
      date = new Date(Date.now())
    }
    const original = date.valueOf()
    date.original = original
    date.setTime(original + DateOverride.offset)
    return date
  }
})(Date)
