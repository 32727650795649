import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { SessionState } from '../session/state/session.state'
import { PresenceModel, PresenceUser } from './state/presence.model'
import { PresenceState } from './state/presence.state'

@Injectable()
export class PresenceService {
  constructor(private store: Store) {}

  /**
   * Get an observable of the currently logged in user's presence.
   *
   * @returns An observable that gives the presence of the currently logged in user.
   */
  getLoggedInUserPresence(): Observable<PresenceUser> {
    const loginIdObservable = this.store.select(SessionState.loginId)
    const presenceStateObservable: Observable<PresenceModel> =
      this.store.select(PresenceState)
    return combineLatest([loginIdObservable, presenceStateObservable]).pipe(
      map(([loginId, presenceState]) => presenceState?.users[loginId]),
      filter((presence?: PresenceUser) => presence != null)
    )
  }

  /**
   * Get the currently logged in user's presence at this point in time.
   *
   * @returns A PresenceUser of the current user.
   */
  getCurrentLoggedInUserPresence(): PresenceUser | undefined {
    const loginId: string | undefined = this.store.selectSnapshot(
      SessionState.loginId
    )
    const presence: PresenceModel | undefined =
      this.store.selectSnapshot(PresenceState)

    return presence?.users[loginId]
  }
}
