export enum MyCallStatuses {
  NOT_ON_CALL = 'available',
  ORIGINATING = 'originating',
  ON_CALL = 'on-call',
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  MISSED = 'missed',
  ENDED = 'ended',
  NO_ANSWER = 'no-answer',
  TRANSFERRED = 'transferred'
}
