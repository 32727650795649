import { Action, State, StateContext } from '@ngxs/store'
import { MyCall, MyCallStatusModel } from './my-call-status.model'
import {
  EndedCall,
  IncomingCall,
  MissedCall,
  NoAnswer,
  OffCall,
  OnCall,
  OriginatingCall,
  OutgoingCall,
  TransferredCall
} from './my-call-status.actions'
import { MyCallStatuses } from './my-call-statuses'
import { Injectable } from '@angular/core'
import { OpenLookup } from '../../../../integrations/panel/state/integration.actions'
import { PhonePipe } from '../../../../common/pipes/phone.pipe'
import { CallStatus, updateLine, useSoftphone } from '@cytracom/softphone'
const callStatusMap = {
  available: 'inactive',
  originating: 'originating',
  'on-call': 'connected',
  outgoing: 'dialing',
  incoming: 'incoming',
  missed: 'missed',
  ended: 'ended',
  'no-answer': 'ended',
  transferred: 'transferred'
}
@State<MyCallStatusModel>({
  name: 'myCallStatus',
  defaults: new MyCallStatusModel()
})
@Injectable()
export class MyCallStatusState {
  phonePipe = new PhonePipe()
  dismissStatusTimeout(
    context: StateContext<MyCallStatusModel>,
    action: any,
    idProp = 'uniqueId',
    status: string,
    dismissAfterMs: number
  ) {
    setTimeout(() => {
      const current = { ...context.getState() }
      const identifier = action[idProp]
      if (
        current.calls[identifier] &&
        current.calls[identifier].status === status
      ) {
        context.dispatch(new OffCall(action.name, action.number, identifier))
        const lines = Object.entries(current.calls)
        const index = lines.findIndex(([key, line]) => key === action.uniqueId)

        if (!useSoftphone.getState().useJanus) {
          useSoftphone.getState().clearLine(index + 1, undefined)
        }
      }
    }, dismissAfterMs)
  }

  @Action(OriginatingCall)
  onOriginate(
    context: StateContext<MyCallStatusModel>,
    action: OriginatingCall
  ) {
    const current = { ...context.getState() }
    current.currentUniqueId = action.uniqueId
    current.calls = {
      ...current.calls,
      [action.uniqueId]: {
        status: MyCallStatuses.ORIGINATING,
        direction: 'outbound',
        name: this.getName(action.name, action.number),
        number: action.number,
        start: null
      }
    }
    context.setState(current)
    this.dismissStatusTimeout(
      context,
      action,
      'uniqueId',
      MyCallStatuses.ORIGINATING,
      10 * 1000
    )
    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.uniqueId)
      const line = lines[index][1]
      updateLine({
        lineId: index + 1,
        callStatus: callStatusMap[line.status] as CallStatus,
        requestUri: action.number,
        displayName: line.name,
        callStarted: line.start || undefined,
        callEnded: undefined,
        missedCall: false,
        callId: action.uniqueId,
        callInitiated: Date.now()
      })
    }
  }

  @Action(IncomingCall)
  onIncoming(context: StateContext<MyCallStatusModel>, action: IncomingCall) {
    const current = { ...context.getState() }
    current.currentUniqueId = action.uniqueId
    current.calls = {
      ...current.calls,
      [action.uniqueId]: {
        status: MyCallStatuses.INCOMING,
        direction: 'inbound',
        name: this.getName(action.name, action.number),
        number: action.number,
        start: null,
        startString: ''
      }
    }
    context.setState(current)
    this.dismissStatusTimeout(
      context,
      action,
      'uniqueId',
      MyCallStatuses.INCOMING,
      !useSoftphone.getState().useJanus ? 10 * 1000 : 65 * 1000
    )
    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.uniqueId)
      const line = lines[index][1]
      updateLine({
        lineId: index + 1,
        callStatus: callStatusMap[line.status] as CallStatus,
        requestUri: action.number,
        displayName: line.name,
        callStarted: line.start || undefined,
        callEnded: undefined,
        missedCall: false,
        callId: action.uniqueId,
        callInitiated: Date.now()
      })
    }
  }

  @Action(OutgoingCall)
  onOutgoing(context: StateContext<MyCallStatusModel>, action: OutgoingCall) {
    const current = { ...context.getState() }
    current.currentUniqueId = action.uniqueId
    current.calls = {
      ...current.calls,
      [action.uniqueId]: {
        status: MyCallStatuses.OUTGOING,
        direction: 'outbound',
        name: this.getName(action.name, action.number),
        number: action.number,
        start: null,
        startString: ''
      }
    }
    delete current.calls.originating
    context.setState(current)

    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.uniqueId)
      const line = lines[index][1]
      updateLine({
        lineId: index + 1,
        callStatus: callStatusMap[line.status] as CallStatus,
        requestUri: action.number,
        displayName: line.name,
        callStarted: line.start || undefined,
        callEnded: undefined,
        missedCall: false,
        callId: action.uniqueId,
        callInitiated: Date.now(),
        incomingCall: undefined
      })
    }
  }

  @Action(NoAnswer)
  onNoAnswer(context: StateContext<MyCallStatusModel>, action: NoAnswer) {
    const current = { ...context.getState() }
    if (!current.calls[action.channelId]) {
      return
    }
    current.currentUniqueId = action.channelId
    current.calls = {
      ...current.calls,
      [action.channelId]: {
        status: MyCallStatuses.NO_ANSWER,
        direction: current.calls[action.channelId]?.direction || 'outbound',
        name: this.useExistingName(current, action),
        number: action.number,
        start: null
      }
    }
    context.setState(current)
    this.dismissStatusTimeout(
      context,
      action,
      'channelId',
      MyCallStatuses.NO_ANSWER,
      3000
    )
    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.channelId)
      useSoftphone.getState().hungUp(
        index + 1,
        (timestamp) => {
          useSoftphone.getState().clearLine(index + 1, timestamp)
        },
        true
      )
    }
  }

  private useExistingName(current, action) {
    const call = current.calls[action.channelId]
    return call ? call.name : this.getName(call?.name, action.number)
  }

  @Action(MissedCall)
  onMissed(context: StateContext<MyCallStatusModel>, action: MissedCall) {
    const current = { ...context.getState() }
    if (!current.calls[action.uniqueId]) {
      return
    }
    current.currentUniqueId = action.uniqueId
    current.calls = {
      ...current.calls,
      [action.uniqueId]: {
        status: MyCallStatuses.MISSED,
        direction: 'inbound',
        name: this.useExistingName(current, action),
        number: action.number,
        start: null,
        startString: ''
      }
    }
    context.setState(current)
    this.dismissStatusTimeout(
      context,
      action,
      'uniqueId',
      MyCallStatuses.MISSED,
      3000
    )
    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.uniqueId)
      useSoftphone.getState().hungUp(index + 1, (timestamp) => {
        useSoftphone.getState().clearLine(index + 1, timestamp)
      })
    }
  }

  @Action(OnCall)
  onOn(context: StateContext<MyCallStatusModel>, action: OnCall) {
    const current = context.getState()
    if (
      current.calls[action.uniqueId]?.direction === 'inbound' &&
      action.number.length > 8
    ) {
      context.dispatch(
        new OpenLookup(
          {
            label: this.phonePipe.transform(action.number),
            values: [action.number]
          },
          true
        )
      )
    }
    context.setState({
      currentUniqueId: action.uniqueId,
      calls: {
        ...current.calls,
        [action.uniqueId]: {
          status: MyCallStatuses.ON_CALL,
          direction: current.calls[action.uniqueId]?.direction || 'outbound',
          name: this.getName(action.name, action.number),
          number: action.number,
          start: new Date().valueOf(),
          startString: new Date().toString()
        }
      }
    })
    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.uniqueId)
      const line = lines[index][1]
      updateLine({
        lineId: index + 1,
        callStatus: callStatusMap[line.status] as CallStatus,
        requestUri: action.number,
        displayName: line.name,
        callStarted: line.start || undefined,
        callEnded: undefined,
        missedCall: false
      })
    }
  }

  @Action(EndedCall)
  onEnded(context: StateContext<MyCallStatusModel>, action: EndedCall) {
    const current = { ...context.getState() }
    if (
      !current.calls ||
      !current.calls[action.channelId] ||
      this.hasAlreadyEnded(current.calls[action.channelId])
    ) {
      return
    }
    current.currentUniqueId = action.channelId
    current.calls = {
      ...current.calls,
      [action.channelId]: {
        status: MyCallStatuses.ENDED,
        direction: current.calls[action.channelId]?.direction || 'outbound',
        name: this.useExistingName(current, action),
        number: action.number,
        start: current.calls[action.channelId].start,
        startString: current.calls[action.channelId].startString
      }
    }
    context.setState(current)
    this.dismissStatusTimeout(
      context,
      action,
      'channelId',
      MyCallStatuses.ENDED,
      3000
    )
    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.channelId)
      useSoftphone.getState().hungUp(index + 1, (timestamp) => {
        useSoftphone.getState().clearLine(index + 1, timestamp)
      })
    }
  }

  @Action(TransferredCall)
  onTransferred(
    context: StateContext<MyCallStatusModel>,
    action: TransferredCall
  ) {
    const current = { ...context.getState() }
    if (
      !current.calls[action.channelId] ||
      this.hasAlreadyEnded(current.calls[action.channelId])
    ) {
      return
    }
    const originalChannelId = current.currentUniqueId
    current.currentUniqueId = action.channelId
    current.calls = {
      ...current.calls,
      [action.channelId]: {
        status: MyCallStatuses.TRANSFERRED,
        direction: current.calls[action.channelId]?.direction || 'outbound',
        name: this.useExistingName(current, action),
        number: action.number,
        start: current.calls[action.channelId].start,
        startString: current.calls[action.channelId].startString
      }
    }
    delete current.calls[originalChannelId]
    context.setState(current)
    this.dismissStatusTimeout(
      context,
      action,
      'channelId',
      MyCallStatuses.TRANSFERRED,
      3000
    )

    if (!useSoftphone.getState().useJanus) {
      const lines = Object.entries(current.calls)
      const index = lines.findIndex(([key, line]) => key === action.channelId)
      useSoftphone.getState().hungUp(
        index + 1,
        (timestamp) => {
          useSoftphone.getState().clearLine(index + 1, timestamp)
        },
        true,
        true
      )
    }
  }

  @Action(OffCall)
  onOff(context: StateContext<MyCallStatusModel>, action: OffCall) {
    const current = { ...context.getState() }
    if (current.currentUniqueId === action.channelId) {
      current.currentUniqueId = ''
    }
    current.calls = {
      ...current.calls
    }
    delete current.calls[action.channelId]

    context.setState(current)
  }

  private hasAlreadyEnded(call: MyCall) {
    return [
      MyCallStatuses.TRANSFERRED,
      MyCallStatuses.ENDED,
      MyCallStatuses.MISSED,
      MyCallStatuses.NO_ANSWER
    ].includes(call.status)
  }

  getName(name: string, number: string) {
    if (
      name?.toLowerCase().includes('cytracom') ||
      name?.toLowerCase().includes('outbound')
    ) {
      name = ''
    }
    return name || this.phonePipe.transform(number)
  }
}
