import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { SessionData } from '../../page/login/session.data'
import { EvhubConnection } from './evhub.connection'
import { WebsocketConnection } from './websocket.connection'

export declare type ConnectionType = 'presence' | 'push' | 'device'
export declare type WebsocketType = 'presence'

@Injectable({
  providedIn: 'root'
})
export class ConnectionManager {
  connected = new BehaviorSubject(false)
  private evHub: EvhubConnection
  private connecting = false

  constructor() {
    const evhubSocket = new WebsocketConnection()
    this.evHub = new EvhubConnection(evhubSocket)
  }

  subscribe(
    event: string,
    cb: (...args: any[]) => void,
    once = false,
    unsubscribe?: Observable<unknown>
  ) {
    this.evHub.subscribe(event, cb, once, unsubscribe)
  }

  on$(event: string): Observable<any> {
    // TODO: remove this method. It is being used as a stop-gap in making the evHub property private.
    return this.evHub.on$(event)
  }

  emit(
    event: string,
    data: { [key: string]: any } = {},
    id?: string
  ): Promise<void> {
    // TODO: this could handle more than one connection (it used to before I was in here and removed SSE)
    // but right now we don't need to. Future implementors may want to add some sort of "DESTINATION" enum
    // on this function
    return this.evHub.emit(event, data, id)
  }

  async connect(session: SessionData) {
    if (!this.connecting) {
      this.connecting = true

      const evhubUrl = EvhubConnection.getUrl(session.app_token)
      try {
        await this.evHub.connect(evhubUrl)
      } finally {
        this.connecting = false
      }
      this.connected.next(true)

      this.evHub.on('open', () => {
        this.connected.next(true)
      })

      this.evHub.on('online', (event) => {
        // We know that online events _should_ have a connected
        // property, but in case they don't, we will be conservative and say we are online.
        //
        // If we come online, we should set ourselves to "online". This may not technically be true,
        // but we will not get a message on the socket if we go off/online and somehow the TCP
        // connection does persist.

        this.connected.next(event?.connected || event?.connected === undefined)
      })

      this.evHub.on('offline', () => {
        this.connected.next(false)
      })

      this.evHub.on('close', () => {
        this.connected.next(false)
      })
    }
  }

  disconnect() {
    if (this.connected.getValue()) {
      this.connected.next(false)
      if (this.evHub) {
        this.evHub.disconnect()
      }
    }
  }
}
