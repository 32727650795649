import { Action, createSelector, State, StateContext } from '@ngxs/store'
import { PresenceModel, PresenceUser } from './presence.model'
import { Presence } from './presence.actions'
import { Injectable } from '@angular/core'
import { UserStatusState, useUserStatus } from '@cytracom/desktop'
import { unstable_batchedUpdates } from 'react-dom'

@State<PresenceModel>({
  name: 'presence',
  defaults: new PresenceModel()
})
@Injectable()
export class PresenceState {
  static byEmail(email: string) {
    return createSelector(
      [PresenceState],
      (state?: PresenceModel): PresenceUser => {
        const presenceUser = state?.users[email] ?? new PresenceUser()
        return presenceUser
      }
    )
  }

  @Action(Presence.Online.Load)
  onLoadOnline(
    context: StateContext<PresenceModel>,
    action: Presence.Online.Load
  ) {
    const newAngularState = this.mergeUserListWithCurrentAngularState(
      context,
      action.state.users,
      (presenceUser) => ({ ...presenceUser, online: true })
    )
    context.setState(newAngularState)

    action.state.users.forEach((email) => {
      this.updateReactUserState((state) =>
        state.updateOnlineStatus(email, true)
      )
    })
  }

  @Action(Presence.DND.Load)
  onLoadDND(context: StateContext<PresenceModel>, action: Presence.DND.Load) {
    const newAngularState = this.mergeUserListWithCurrentAngularState(
      context,
      action.state.users,
      (presenceUser) => ({ ...presenceUser, do_not_disturb: true })
    )
    context.setState(newAngularState)

    action.state.users.forEach((email) => {
      this.updateReactUserState((state) =>
        state.updateDoNotDisturbStatus(email, true)
      )
    })
  }

  @Action(Presence.Online.Update)
  onUpdateOnline(
    context: StateContext<PresenceModel>,
    action: Presence.Online.Update
  ) {
    const newAngularState = this.setUserPresenceInAngularState(
      context,
      action.user,
      (presenceUser) => ({ ...presenceUser, online: action.online })
    )

    context.setState(newAngularState)
    this.updateReactUserState((state) =>
      state.updateOnlineStatus(action.user, action.online)
    )
  }

  @Action(Presence.DND.Update)
  onUpdateDND(
    context: StateContext<PresenceModel>,
    action: Presence.DND.Update
  ) {
    const newAngularState = this.setUserPresenceInAngularState(
      context,
      action.user,
      (presenceUser) => ({
        ...presenceUser,
        do_not_disturb: action.do_not_disturb
      })
    )

    context.setState(newAngularState)
    this.updateReactUserState((state) =>
      state.updateDoNotDisturbStatus(action.user, action.do_not_disturb)
    )
  }

  private mergeUserListWithCurrentAngularState(
    context: StateContext<PresenceModel>,
    users: string[],
    mapper: (presenceUser: PresenceUser) => PresenceUser
  ): PresenceModel {
    const state: PresenceModel = {
      ...(context.getState() ?? new PresenceModel())
    }
    const updatedUsers = { ...state.users }
    users.forEach((user) => {
      const currentUser = state.users[user] ?? new PresenceUser()
      updatedUsers[user] = mapper(currentUser)
    })
    state.users = updatedUsers

    return state
  }

  private setUserPresenceInAngularState(
    context: StateContext<PresenceModel>,
    user: string,
    mapper: (presenceUser: PresenceUser) => PresenceUser
  ): PresenceModel {
    const state: PresenceModel = {
      ...(context.getState() ?? new PresenceModel())
    }
    const updatedUsers = { ...state.users }
    updatedUsers[user] = mapper(updatedUsers[user] ?? new PresenceUser())
    state.users = updatedUsers

    return state
  }

  private updateReactUserState(modifier: (state: UserStatusState) => void) {
    // Since we're calling some mutator outside of react, we actually
    // won't see our changes reflected in our components unless we wrap this
    // in unstable_batchedUpdates. This is also fixed if we upgrade to React 18
    // See: https://docs.pmnd.rs/zustand/guides/event-handler-in-pre-react-18
    unstable_batchedUpdates(() => {
      modifier(useUserStatus.getState())
    })
  }
}
