import {
  DidData,
  SIPCredentials,
  SessionData
} from '../../../page/login/session.data'

export class StoreSession {
  static readonly type = '[Session] Store'

  constructor(public data: SessionData) {}
}

export class UpdateSIPCredentials {
  static readonly type = '[Session] Update SIP Credentials'

  constructor(public newCredentials: SIPCredentials[]) {}
}

export class UpdateDids {
  static readonly type = '[Session] Update Dids'

  constructor(public data: DidData) {}
}

export class DestroySession {
  static readonly type = '[Session] Destroy'

  constructor() {}
}

export class UpdateAvatar {
  static readonly type = '[Session] Update Avatar'

  constructor(public avatar: SessionData['avatar_url']) {}
}
