import { Injectable, Type } from '@angular/core'
import { SnackBarComponent } from '../../common/components/snack-bar.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ToastrService, ActiveToast } from 'ngx-toastr'
import { ToastConfig } from './toast-config'
import { NotificationComponent } from './notification.component'
import {
  NOTIFICATION_IGNORED_STATUS,
  WebNotificationService
} from './web-notification.service'
import { SoundsService } from '../../common/services/sounds/sounds.service'
import { PresenceService } from '../presence/presence.service'
import { ElectronService } from '..//electron/electron.service'

@Injectable()
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private toast: ToastrService,
    private web: WebNotificationService,
    private sounds: SoundsService,
    private electron: ElectronService,
    private presenceService: PresenceService
  ) {}

  snack(
    message: string,
    duration = 2000,
    component: Type<any> = SnackBarComponent
  ) {
    return this.snackBar.openFromComponent(component, {
      duration,
      data: { dismissOnClick: true, message },
      horizontalPosition: 'center',
      verticalPosition: 'top'
    })
  }

  notification<T = NotificationComponent>(
    title: string,
    message: string,
    config: Partial<ToastConfig<T>> = {}
  ): ActiveToast<T> {
    if (this.electron.isRunningInElectron()) {
      this.electron.isOSOnDND().then((result) => {
        if (!result) this.sounds.play('notification-open')
      })
    } else {
      this.sounds.play('notification-open')
    }

    return this.toast.show(message, title, {
      toastComponent: NotificationComponent,
      ...config
    })
  }

  webNotification(
    config: { title: string } & NotificationOptions
  ): Promise<string> {
    if (this.isUserOnDND()) {
      return Promise.resolve(NOTIFICATION_IGNORED_STATUS)
    }

    return this.web.send(config)
  }

  private isUserOnDND(): boolean {
    return (
      this.presenceService.getCurrentLoggedInUserPresence()?.do_not_disturb ??
      false
    )
  }
}
