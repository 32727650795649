import { Component, HostBinding, Inject } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'profile-avatar',
  templateUrl: 'profile-avatar.component.html',
  styleUrls: ['profile-avatar.component.scss'],
  inputs: ['image']
})
export class ProfileAvatarComponent {
  @HostBinding('style.background-image')
  style: any = null

  constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) {}

  set image(value: string) {
    if (value && value !== '') {
      this.style = this.sanitizer.bypassSecurityTrustStyle(
        'url("' + value + '")'
      )
      return
    }
    this.style = ''
  }
}
