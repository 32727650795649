import { catchError, first, mergeMap } from 'rxjs/operators'
import { interval, merge, Observable, of, throwError } from 'rxjs'

export function timeout<T>(
  observable: Observable<T>,
  milliseconds: number,
  valueToReturn: T
) {
  return merge(
    interval(milliseconds).pipe(
      first(),
      mergeMap(() => throwError('Timeout'))
    ),
    observable
  ).pipe(
    catchError((e) => {
      if (e === 'Timeout') {
        return of(valueToReturn)
      }
      return throwError(e)
    })
  )
}
