import {
  AfterViewInit,
  Directive,
  EventEmitter,
  NgZone,
  OnDestroy
} from '@angular/core'
import { ConnectionManager } from '../connections/connection.manager'
import { Store } from '@ngxs/store'
import { Presence } from './state/presence.actions'
import {
  InitialPresenceState,
  PresenceUpdateDNDModel,
  PresenceUpdateOnlineModel
} from './state/presence.model'
import { unstable_batchedUpdates } from 'react-dom'
import { useSoftphone } from '@cytracom/softphone'
import { PresenceService } from './presence.service'
import { SessionState } from '../session/state/session.state'
import { withLatestFrom } from 'rxjs/operators'

@Directive({
  selector: '[presence]'
})
export class PresenceDirective implements AfterViewInit, OnDestroy {
  destroy = new EventEmitter()

  constructor(
    private connection: ConnectionManager,
    private presenceService: PresenceService,
    private store: Store,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.subscribeToPresenceEvents()
      this.subscribeToEvhubSocketState()
      this.subscribeToPresenceForSoftphone()
    })
  }

  ngOnDestroy() {
    this.destroy.emit()
  }

  private subscribeToEvhubSocketState() {
    const loginIdObservable = this.store.select(SessionState.loginId)
    const subscription = this.connection.connected
      .pipe(withLatestFrom(loginIdObservable))
      .subscribe(([connected, loginId]: [boolean, string]) => {
        // We are marked as 'offline' when we disconnect from the socket.
        // However, being brought online will come from a message in the socket itself.
        if (connected) {
          this.store.dispatch(new Presence.Online.Update(loginId, true))
        } else {
          this.store.dispatch(new Presence.Online.Update(loginId, false))
        }
      })

    this.destroy.subscribe(() => {
      subscription.unsubscribe()
    })
  }

  private subscribeToPresenceEvents() {
    this.connection.subscribe(
      'presence.status',
      (status: InitialPresenceState) => {
        this.store.dispatch(new Presence.Online.Load(status))
      },
      false,
      this.destroy
    )

    this.connection.subscribe(
      'dnd.status',
      (status: InitialPresenceState) => {
        this.store.dispatch(new Presence.DND.Load(status))
      },
      false,
      this.destroy
    )

    this.connection.subscribe(
      'presence.update',
      ({ login_id, status }: PresenceUpdateOnlineModel) => {
        this.store.dispatch(
          new Presence.Online.Update(login_id, status !== 'offline')
        )
      },
      false,
      this.destroy
    )

    this.connection.subscribe(
      'dnd.update',
      ({ data: { login_id }, status }: PresenceUpdateDNDModel) => {
        this.store.dispatch(
          new Presence.DND.Update(login_id, status === 'enabled')
        )
      },
      false,
      this.destroy
    )
  }

  private subscribeToPresenceForSoftphone() {
    const subscription = this.presenceService
      .getLoggedInUserPresence()
      .subscribe((currentUserPresence) => {
        const currentUserDND = currentUserPresence.do_not_disturb

        unstable_batchedUpdates(() => {
          // Since we're calling some mutator outside of react, we actually
          // won't see our changes reflected in our components unless we wrap this
          // in unstable_batchedUpdates. This is also fixed if we upgrade to React 18
          // See: https://docs.pmnd.rs/zustand/guides/event-handler-in-pre-react-18
          useSoftphone.getState().updateDoNotDisturb(currentUserDND)
        })
      })

    this.destroy.subscribe(() => {
      subscription.unsubscribe()
    })
  }
}
