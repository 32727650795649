import { NgModule } from '@angular/core'
import { PresenceModule } from 'projects/system/presence/presence.module'
import { SoundsService } from './sounds.service'
import { SOUNDS_ENABLED, SOUNDS_TO_REGISTER } from './sounds.tokens'

@NgModule({
  imports: [PresenceModule],
  providers: []
})
export class SoundsModule {
  static forRoot() {
    return {
      ngModule: SoundsModule,
      providers: [
        SoundsService,
        {
          provide: SOUNDS_ENABLED,
          useValue: false
        },
        {
          provide: SOUNDS_TO_REGISTER,
          multi: true,
          useValue: [
            {
              key: 'button',
              config: {
                source: '/assets/sounds/button.mp3',
                volume: 0.5
              }
            },
            {
              key: 'confirmation',
              config: {
                source: '/assets/sounds/confirmation.mp3',
                volume: 0.5
              }
            },
            {
              key: 'error',
              config: {
                source: '/assets/sounds/error.mp3',
                volume: 0.5
              }
            },
            {
              key: 'hangup',
              config: {
                source: '/assets/sounds/hangup.mp3',
                volume: 0.5
              }
            },
            {
              key: 'sent-message',
              config: {
                source: '/assets/sounds/sent-message.mp3',
                volume: 0.5
              }
            },
            {
              key: 'received-message',
              config: {
                source: '/assets/sounds/received-message.mp3',
                volume: 0.5
              }
            },
            {
              key: 'outgoing-call',
              config: {
                source: '/assets/sounds/outgoing-call.mp3',
                volume: 0.7,
                loop: true
              }
            },
            {
              key: 'incoming-call',
              config: {
                source: '/assets/sounds/incoming-call.mp3',
                volume: 0.7,
                loop: true
              }
            },
            {
              key: 'notification-open',
              config: {
                source: '/assets/sounds/notification.mp3',
                volume: 0.5
              }
            },
            {
              key: 'default-ringtone',
              config: {
                source: '/assets/sounds/incoming-call.mp3'
              }
            },
            {
              key: 'new-ringtone',
              config: {
                source: '/assets/sounds/ringtones/ringtone_new.mp3'
              }
            },
            {
              key: 'office-phone',
              config: {
                source: '/assets/sounds/ringtones/office_phone_4.mp3'
              }
            },
            {
              key: 'old-telephone',
              config: {
                source: '/assets/sounds/ringtones/old_telephone.mp3'
              }
            },
            {
              key: 'phone-d',
              config: {
                source: '/assets/sounds/ringtones/phone_d_ringtone.mp3'
              }
            },
            {
              key: 'phone-drings',
              config: {
                source: '/assets/sounds/ringtones/phone_drings.mp3'
              }
            },
            {
              key: 'phone-dying',
              config: {
                source: '/assets/sounds/ringtones/phone_dying.mp3'
              }
            },
            {
              key: 'phone-riing',
              config: {
                source: '/assets/sounds/ringtones/phone_riiiiiiing.mp3'
              }
            },
            {
              key: 'phone-ring-echo',
              config: {
                source: '/assets/sounds/ringtones/phone_ring_echo.mp3'
              }
            },
            {
              key: 'pulsating-ring',
              config: {
                source: '/assets/sounds/ringtones/phone_ring.mp3'
              }
            },
            {
              key: 'rapid-pulse-ring',
              config: {
                source: '/assets/sounds/ringtones/phone_ringtone.mp3'
              }
            },
            {
              key: 'repeating-ringtone',
              config: {
                source: '/assets/sounds/ringtones/ringtoneRepeat.wav'
              }
            },
            {
              key: 'ringtone-plus',
              config: {
                source: '/assets/sounds/ringtones/ringtone_plus_ii.mp3'
              }
            },
            {
              key: 'telephone-ring-2012',
              config: {
                source: '/assets/sounds/ringtones/telephone_ring_2012.mp3'
              }
            }
          ]
        }
      ]
    }
  }
}
