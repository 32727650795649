import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { SessionState } from './state/session.state'

@Injectable()
export class SessionGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store, private router: Router) {}

  // Go To Home Route If Logged In
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return !this.store.selectSnapshot(SessionState) || this.router.parseUrl('')
  }

  // Go To Login If Not Logged In
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return (
      Boolean(this.store.selectSnapshot(SessionState)) ||
      this.router.parseUrl('login')
    )
  }
}
