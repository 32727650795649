import { Action, createSelector, State, StateContext } from '@ngxs/store'
import { PermissionModel } from './permission.model'
import { SetPermission } from './permission.actions'
import { Permission } from './permission.constants'
import { DestroySession } from './session.actions'
import { Injectable } from '@angular/core'

@State<PermissionModel>({
  name: 'permissions',
  defaults: new PermissionModel()
})
@Injectable()
export class PermissionState {
  static canAccess(permission: Permission) {
    return createSelector([PermissionState], (permissions: PermissionModel) => {
      return permissions[permission] || false
    })
  }

  @Action(SetPermission)
  onSet(context: StateContext<PermissionModel>, action: SetPermission) {
    context.patchState({
      [action.permission]: action.isAllowed
    })
  }

  @Action(DestroySession)
  onReset(context: StateContext<PermissionModel>, action: DestroySession) {
    context.setState(new PermissionModel())
  }
}
