import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { interval, Observable, throwError } from 'rxjs'
import { Store } from '@ngxs/store'
import { environment } from '../../../src/environments/environment'
import { SessionState } from '../session/state/session.state'
import { catchError, first, switchMap } from 'rxjs/operators'
import { DestroySession } from '../session/state/session.actions'
import { getError } from './api.errors'
import { PassThroughCodec } from './pass-through.codec'
import { encodeAppToken } from './encode-app-token'
import { NotificationService } from '../notifications/notification.service'
import { DateOverride } from '../../common/overrides/date'
import { version } from '../../../package.json'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  hasDestroyedSession = false

  constructor(
    private store: Store,
    private notification: NotificationService
  ) {}

  ntp(t0, t1, t2, t3) {
    return {
      roundtripdelay: t3 - t0 - (t2 - t1),
      offset: (t1 - t0 + (t2 - t3)) / 2
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf('/v1.0') === 0 ||
      req.url.indexOf(environment.messaging + '/v1.0') === 0 ||
      req.url.indexOf('/v2.0') === 0 ||
      req.url.indexOf('ihub.cytracom.net') !== -1
    ) {
      const session = this.store.selectSnapshot(SessionState)
      if (session?.app_token) {
        this.hasDestroyedSession = false
        const request = this.provideToken(req, session)
        return this.handleCancellation(next, request).pipe(
          catchError((error) => this.handleHttpError(error, next, request))
        )
      }
      const prependRequest = this.prependBackendUrl(req)
      return this.handleCancellation(next, prependRequest).pipe(
        catchError((error) => this.handleHttpError(error, next, prependRequest))
      )
    }
    return this.handleCancellation(next, req)
  }

  private prependBackendUrl(req: HttpRequest<any>, url?: string) {
    let request = req
    if (url) {
      request = req.clone({ url })
    }
    if (request.url.indexOf('http') !== 0) {
      request = request.clone({
        url: environment.backend + request.url
      })
    }

    const params = request.params.append('ngsw-bypass', 'true')
    const headers = request.headers.append('cdesktop-ver', version)

    return request.clone({
      params,
      headers
    })
  }

  private provideToken(req: HttpRequest<any>, session) {
    const url = req.url.replace('{token}', encodeAppToken(session.app_token))
    const clone = this.prependBackendUrl(req, url)
    if (url === req.url) {
      let params = new HttpParams({
        fromObject: req.params.keys().reduce((acc, key) => {
          acc[key] = req.params.get(key)
          return acc
        }, {}),
        encoder: new PassThroughCodec()
      })
      params = params.append('token', encodeAppToken(session.app_token))
      return clone.clone({ params })
    }

    return clone
  }

  private handleHttpError(
    error: HttpErrorResponse,
    next: HttpHandler,
    request: HttpRequest<any>,
    failureTick = 1,
    failureStartTime?: Date
  ): Observable<any> {
    const retryErrorStatuses = [0, 500, 502, 503, 504, 429]

    if (retryErrorStatuses.indexOf(error.status) > -1) {
      if (!failureStartTime) {
        failureStartTime = new Date()
      } else {
        // If a request has been failing for 5 minutes or more, then give up.
        const date = new Date()
        let diff = (date.getTime() - failureStartTime.getTime()) / 1000
        diff /= 60
        const minutes = Math.abs(Math.round(diff))
        if (minutes >= 5) {
          return throwError(getError(error.status, error.error))
        }
      }

      const exponentialBackOff = failureTick * 2

      return interval(1000 * exponentialBackOff)
        .pipe(first())
        .pipe(switchMap(() => this.handleCancellation(next, request)))
        .pipe(
          catchError((err) =>
            this.handleHttpError(
              err,
              next,
              request,
              exponentialBackOff,
              failureStartTime
            )
          )
        )
    }

    if (error.status === 401) {
      if (error.error && error.error.error_code === 40101) {
        return throwError(getError(error.status, error.error))
      }
      if (!this.hasDestroyedSession) {
        const session = this.store.selectSnapshot(SessionState)
        if (session) {
          this.hasDestroyedSession = true
          this.store.dispatch(new DestroySession())
          this.notification.snack('Session Expired - Please login again')
        }
      }
    }
    return throwError(getError(error.status, error.error))
  }

  handleCancellation(
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<any> {
    const beforeTime = new Date() as any
    beforeTime.setUTCDate(beforeTime.original)

    return new Observable((observer) => {
      let cancelled = true
      next.handle(request).subscribe({
        next: (value) => {
          if (value instanceof HttpResponse) {
            cancelled = false
            observer.next(value)
            if (value.headers.get('Date') && !DateOverride.hasChangedOffset) {
              const date = value.headers.get('Date')
              if (Number(date) + '' === date + '') {
                const now = new Date() as any
                now.setUTCDate(now.original)
                const info = this.ntp(
                  beforeTime.valueOf(),
                  date,
                  date,
                  now.valueOf()
                )
                if (DateOverride.offset !== info.offset) {
                  DateOverride.offset = info.offset
                }
                DateOverride.hasChangedOffset = true
              }
            }
          }
        },
        error: (error) => {
          cancelled = false
          observer.error(error)
        },
        complete: () => {
          if (cancelled) {
            observer.error(
              new HttpErrorResponse({
                error: 'Cancelled',
                status: 0
              })
            )
          }
        }
      })
    }).pipe(first())
  }
}
