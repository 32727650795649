import { NgModule } from '@angular/core'
import { EmptyStateComponent } from './empty-state.component'
import { EmptyStateImagesComponent } from './empty-state-images.component'
import { UiModule } from 'ui'

@NgModule({
  declarations: [EmptyStateComponent, EmptyStateImagesComponent],
  imports: [UiModule],
  exports: [EmptyStateComponent, EmptyStateImagesComponent]
})
export class EmptyStateModule {}
