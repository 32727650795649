import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../src/environments/environment'
import { SessionData } from './session.data'
import { Store } from '@ngxs/store'
import { StoreSession } from '../../system/session/state/session.actions'
import { TwoFactorRequired } from '../../system/api/api.errors'
import { Router } from '@angular/router'
import { tracking } from '../../system/analytics/tracking'
import { FeatureFlagService } from '../../common/services/feature-flag.service'

@Injectable()
export class LoginService {
  requestRequiringTwoFactor: {
    did?: string
    extension_number?: string
    login_id?: string
    passwd: string
  } = {
    passwd: ''
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private featureFlagService: FeatureFlagService
  ) {}

  async loginWithEmail(email: string, password: string) {
    try {
      const result = await this.callApi({
        login_id: email,
        passwd: password
      })
      if (!result?.extension_number) {
        throw new Error('extension')
      }

      tracking.track('login.email.success')
      this.store.dispatch(new StoreSession(result))

      await this.featureFlagService.initialize()
      await this.router.navigate([''])
    } catch (e) {
      if (e instanceof TwoFactorRequired) {
        this.requestRequiringTwoFactor = {
          login_id: email,
          passwd: password
        }
      } else {
        tracking.track('login.email.error', {
          'login.error.code': typeof e === 'string' ? e : e.status
        })
      }

      throw e
    }
  }

  async loginWithExtension(did: string, extension: string, password: string) {
    try {
      const result = await this.callApi({
        did,
        extension_number: extension,
        passwd: password
      })

      if (!result?.extension_number) {
        throw new Error('extension')
      }

      tracking.track('login.number.success')
      this.store.dispatch(new StoreSession(result))
      await this.featureFlagService.initialize()
      await this.router.navigate([''])
    } catch (e) {
      if (e instanceof TwoFactorRequired) {
        this.requestRequiringTwoFactor = {
          did,
          extension_number: extension,
          passwd: password
        }
      } else {
        tracking.track('login.number.error', {
          'login.error.code': typeof e === 'string' ? e : e.status
        })
      }

      throw e
    }
  }

  async provideTwoFactorCode(code: string, remember: boolean) {
    if (!this.requestRequiringTwoFactor) {
      throw new Error(
        'Unable to send two factor code without login credentials'
      )
    }

    const result = await this.callApi({
      ...this.requestRequiringTwoFactor,
      pin: code,
      remember_ip: remember
    })

    if (!result?.extension_number) {
      throw new Error('extension')
    }

    this.store.dispatch(new StoreSession(result))
    await this.router.navigate([''])
  }

  async callApi(request) {
    return await this.http
      .post<SessionData>(
        '/v1.0/data/plugin/auth/' + environment.appId,
        request,
        {
          params: {
            with_cytra_secret: 'true',
            with_documo_creds: 'true'
          }
        }
      )
      .toPromise()
  }

  async checkToken(token: string): Promise<boolean> {
    try {
      const result = await this.http
        .get('/v1.0/data/plugin/config/test/' + encodeURIComponent(token))
        .toPromise()
      return !!result
    } catch (e) {
      return false
    }
  }
}
