import { Pipe, PipeTransform } from '@angular/core'
import { formatPhoneNumber } from '../functions/format.functions'
import { isNumeric } from '../functions/string.functions'

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  static seen: { [value: string]: string } = {}

  transform(value: string): string {
    return PhonePipe.transform(value)
  }

  static transform(value: string): string {
    if (!value) {
      return value
    }
    return (PhonePipe.seen[value] = PhonePipe.seen[value] =
      formatPhoneNumber(value) || (isNumeric(value) ? 'Ext ' + value : value))
  }
}
