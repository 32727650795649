import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core'
import { ProfileAvatarComponent } from './profile-avatar.component'
import { AvatarSymbolsComponent } from './avatar-symbols.component'
import { AvatarComponent } from './avatar.component'
import { UiModule } from 'ui'
import { FirstInitialPipeModule } from '../../../common/pipes/first-initial-pipe.module'
import { StatusModule } from '../status/status.module'

@NgModule({
  imports: [UiModule, FirstInitialPipeModule, StatusModule],
  declarations: [
    ProfileAvatarComponent,
    AvatarSymbolsComponent,
    AvatarComponent
  ],
  exports: [ProfileAvatarComponent, AvatarSymbolsComponent, AvatarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AvatarModule {}
