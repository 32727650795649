export class IncomingCall {
  static readonly type = '[My Call Status] Incoming Call'

  constructor(
    public name: string,
    public number: string,
    public uniqueId: string
  ) {}
}

export class MissedCall {
  static readonly type = '[My Call Status] Missed Call'

  constructor(
    public name: string,
    public number: string,
    public uniqueId: string
  ) {}
}

export class OutgoingCall {
  static readonly type = '[My Call Status] Outgoing Call'

  constructor(
    public name: string,
    public number: string,
    public uniqueId: string
  ) {}
}

export class OriginatingCall {
  static readonly type = '[My Call Status] Originating Call'

  constructor(
    public name: string,
    public number: string,
    public uniqueId: string
  ) {}
}

export class OnCall {
  static readonly type = '[My Call Status] On Call'

  constructor(
    public name: string,
    public number: string,
    public uniqueId: string
  ) {}
}

export class OffCall {
  static readonly type = '[My Call Status] Off Call'

  constructor(
    public name: string,
    public number: string,
    public channelId: string
  ) {}
}

export class EndedCall {
  static readonly type = '[My Call Status] Ended Call'

  constructor(
    public name: string,
    public number: string,
    public channelId: string
  ) {}
}

export class TransferredCall {
  static readonly type = '[My Call Status] Transferred Call'

  constructor(
    public name: string,
    public number: string,
    public channelId: string
  ) {}
}

export class NoAnswer {
  static readonly type = '[My Call Status] No Answer'

  constructor(
    public name: string,
    public number: string,
    public channelId: string
  ) {}
}

export class SilenceNoise {
  static readonly type = '[My Call Status] Silence Noise'
}
