export class Typing {
  static readonly type = '[Messaging Presence] Typing'

  constructor(public threadId: string, public email: string) {}
}

export class Viewing {
  static readonly type = '[Messaging Presence] Viewing'

  constructor(public threadId: string, public email: string) {}
}
export class StoppedTyping {
  static readonly type = '[Messaging Presence] Stopped Typing'

  constructor(public threadId: string, public email: string) {}
}

export class StoppedViewing {
  static readonly type = '[Messaging Presence] Stopped Viewing'

  constructor(public threadId: string, public email: string) {}
}
