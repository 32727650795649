import { Inject, Injectable } from '@angular/core'
import { SOUNDS_TO_REGISTER } from './sounds.tokens'
import { SoundConfig } from './sounds.models'
import { SoundsConfig } from './sounds.config'
import { Howl, Howler } from 'howler'
import { PresenceService } from '../../../system/presence/presence.service'
import { PresenceUser } from '../../../system/presence/state/presence.model'

const VOLUME_STORAGE_KEY = 'notificationVolume'
const RINGTONE_STORAGE_KEY = 'ringtoneKey'

@Injectable()
export class SoundsService {
  private registry: { [key: string]: SoundConfig } = {}

  private howls: { [key: string]: Howl } = {}

  constructor(
    @Inject(SoundsConfig) private config: SoundsConfig,
    @Inject(SOUNDS_TO_REGISTER)
    sounds: { key: string; config: SoundConfig }[][] = [],
    private presenceService: PresenceService
  ) {
    sounds.forEach((injected) =>
      injected.forEach((value) => this.register(value.key, value.config))
    )
    const ringtoneKey = localStorage.getItem(RINGTONE_STORAGE_KEY)
    if (ringtoneKey) this.changeRingtone(ringtoneKey)
    const volume = localStorage.getItem(VOLUME_STORAGE_KEY)
    if (volume) {
      this.changeVolume(+volume)
    } else {
      this.storeCurrentVolume()
    }
    this.subscribeToPresence()
  }

  register(key: string, config: SoundConfig) {
    if (config) {
      this.registry[key] = config
      this.howls[key] = new Howl({
        src: config.sources || [config.source],
        rate: config.speed || 1,
        preload: true,
        autoplay: false,
        loop: config.loop || false,
        volume: config.volume || 1
      })
    }
  }

  get(key: string) {
    return this.howls[key]
  }

  play(key: string, index?: number, volume?: number) {
    if (this.config.enabled) {
      const sound = this.get(key)
      if (sound) {
        if (volume) {
          sound.volume(volume)
        }

        return sound.play(index)
      }
      console.warn('No sound registered to "' + key + '"')
    }
  }

  getVolume() {
    const storedVolume = parseFloat(localStorage.getItem(VOLUME_STORAGE_KEY))
    return isNaN(storedVolume) ? Howler.volume() : storedVolume
  }

  changeVolume(volume: number) {
    this.setEffectiveVolume(volume)
    this.storeVolume(volume)
  }

  changeSoundVolume(key: string, volume: number) {
    const sound = this.get(key)
    if (sound) {
      return sound.volume(volume)
    }

    console.warn('No sound registered to "' + key + '"')
  }

  changeRingtone(key: string) {
    const newRingtone = this.registry[key]

    this.register('incoming-call', {
      ...this.registry['incoming-call'],
      source: newRingtone.source
    })
    localStorage.setItem(RINGTONE_STORAGE_KEY, key)
  }

  stop(key: string, index?: number) {
    if (this.get(key)) {
      this.get(key).stop(index)
      return
    }

    console.warn('No sound registered to "' + key + '"')
  }

  private setEffectiveVolume(volume: number) {
    Howler.volume(volume)
  }

  private storeCurrentVolume() {
    this.storeVolume(Howler.volume())
  }

  private storeVolume(volume: number) {
    localStorage.setItem(VOLUME_STORAGE_KEY, volume.toString())
  }

  private useStoredVolume() {
    const storedVolume = parseFloat(localStorage.getItem(VOLUME_STORAGE_KEY))
    if (isNaN(storedVolume)) {
      console.warn('No stored volume is available to use')
      return
    }

    Howler.volume(storedVolume)
  }

  private subscribeToPresence() {
    this.presenceService
      .getLoggedInUserPresence()
      .subscribe((presence: PresenceUser) => {
        if (presence.do_not_disturb) {
          this.setEffectiveVolume(0)
        } else {
          this.useStoredVolume()
        }
      })
  }
}
