import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { PresenceState } from './state/presence.state'
import { PresenceDirective } from './presence.directive'
import { ConnectionsModule } from '../connections/connections.module'
import { PresenceService } from './presence.service'

@NgModule({
  imports: [ConnectionsModule, NgxsModule.forFeature([PresenceState])],
  providers: [PresenceService],
  declarations: [PresenceDirective],
  exports: [PresenceDirective, PresenceService]
})
export class PresenceModule {}
