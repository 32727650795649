export class ConversationEvents {
  static readonly NUMBER_UPDATE = 'messaging_update_numbers'
  static readonly TYPING = 'mpres.typing'
  static readonly VIEWING = 'mpres.viewing'
  static readonly MUTED = 'messaging_mute_thread'
  static readonly UNMUTED = 'messaging_unmute_thread'
  static readonly NUMBER_MUTED = 'messaging_mute_number'
  static readonly NUMBER_UNMUTED = 'messaging_unmute_number'
  static readonly LAST_MESSAGE_TIME = 'messaging_last_read_message_timestamp'

  static readonly MESSAGE_RECEIVED = 'messaging_inbound_message'
  static readonly SHARED_MESSAGE_RECEIVED = 'messaging_inbound_shared_message'

  static readonly MESSAGE_DELIVERED = 'messaging_delivery_receipt'
  static readonly MESSAGE_EDITED = 'messaging_edit_message'
  static readonly MESSAGES_DELETED = 'messaging_delete_messages'
  static readonly HIDDEN = 'messaging_hide_threads'
  static readonly RENAMED = 'messaging_name_thread'
  static readonly ROOM_MEMBERS_ADDED = 'messaging_add_users_to_room'
  static readonly ROOM_MEMBERS_REMOVED = 'messaging_remove_users_from_room'

  static readonly ROOM_CREATED = 'messaging_create_room'
  static readonly ROOM_MODIFIED = 'messaging_modify_room'
  static readonly ROOM_LEFT = 'messaging_left_rooms'
  static readonly ROOM_ARCHIVED = 'messaging_archive_rooms'
  static readonly ROOM_UNARCHIVED = 'messaging_unarchive_rooms'
}
