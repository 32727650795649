import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class VisibilityService {
  visible = new BehaviorSubject(false)

  private _visible = true
  private _focused = true

  prefixes = ['webkit', 'moz', 'ms', 'o']

  get visibilityChangeProperty() {
    const prefixes = this.prefixes.filter(
      (prefix) => prefix + 'Hidden' in document
    )
    return prefixes.length
      ? prefixes[0] + 'visibilitychange'
      : 'visibilitychange'
  }

  isHidden(): boolean {
    if ('hidden' in document) {
      return Boolean(document.hidden)
    }
    const prefixes = this.prefixes.filter(
      (prefix) => prefix + 'Hidden' in document
    )
    if (prefixes.length) {
      return Boolean(document[prefixes[0] + 'Hidden'])
    }
    return false
  }

  constructor() {
    document.addEventListener(this.visibilityChangeProperty, () => {
      this._visible = !this.isHidden()
      this.visible.next(this._visible && this._focused)
    })
    window.addEventListener('blur', () => {
      this._focused = false
      this.visible.next(false)
    })
    window.addEventListener('focus', () => {
      this._focused = true
      this.visible.next(this._visible && this._focused)
    })
  }
}
