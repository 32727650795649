import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule } from '@angular/router'
import { SessionModule } from '../../projects/system/session/session.module'
import { SessionGuard } from '../../projects/system/session/session.guard'

@NgModule({
  imports: [
    SessionModule,
    RouterModule.forRoot(
      [
        {
          path: 'login',
          canActivate: [SessionGuard],
          loadChildren: () =>
            import('../../projects/page/login/login-page.module').then(
              (m) => m.LoginPageModule
            )
        },
        {
          path: '',
          canActivateChild: [SessionGuard],
          loadChildren: () =>
            import('../../projects/app/softphone/softphone.module').then(
              (m) => m.SoftphoneModule
            )
        },
        {
          path: '*',
          redirectTo: ''
        }
      ],
      {
        useHash: true,
        enableTracing: false,
        preloadingStrategy: PreloadAllModules,
        anchorScrolling: 'enabled'
      }
    )
  ],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {}
}
