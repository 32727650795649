import { LegacyElectronService } from '../legacy-electron.service'
import { ElectronStrategy } from '../strategy'

export class LegacyStrategy implements ElectronStrategy {
  constructor(private electron: LegacyElectronService) {}

  isOSOnDND(): Promise<boolean> {
    return this.electron.ipcRenderer.invoke('dnd')
  }

  updateBadge(count: number): void {
    this.electron.ipcRenderer.send('badge', count)
    if (this.electron.isWindows) {
      this.electron.ipcRenderer.invoke('update-badge', count)
    }
  }

  sendNotification(
    instance: number,
    config: { title: string } & NotificationOptions
  ): Promise<string> {
    return new Promise<string>((resolve) => {
      this.electron.ipcRenderer.once(
        'notification-' + instance + '-reply',
        (_event, action) => {
          resolve(action)
        }
      )

      this.electron.ipcRenderer.send('notification', { instance, config })
    })
  }

  on(event: string, callback: (...args: any[]) => void): () => void {
    this.electron.ipcRenderer.on(event, callback)

    return () => {
      this.electron.ipcRenderer?.off('call', callback)
    }
  }

  getPlatform(): string {
    return process.platform
  }

  openWindowsMicPage(): void {
    this.electron.shell.openExternal('ms-settings:privacy-microphone')
  }
}
