import { LookupData } from './lookup.data'
import { IntegrationStateModel } from './integration-state.model'
import { IntegrationHistoryNode } from '../integration-history.model'
import { IntegrationApp } from '../../api/integration.api.models'

export class LoadApps {
  static readonly type = '[Integrations] Load Apps'

  constructor(public apps: IntegrationApp[]) {}
}

export class SetCurrentApp {
  static readonly type = '[Integrations] Set Current App'

  constructor(public appId: string) {}
}

export class StoreAppState {
  static readonly type = '[Integrations] Store App State'

  constructor(public appId: string, public state: string) {}
}

export class OpenApp {
  static readonly type = '[Integrations] Open App'

  constructor(public appId: string, public dataFromLookup?: any) {}
}

export class ClearDataFromLookup {
  static readonly type = '[Integrations] Clear Data'
}

export class CloseApp {
  static readonly type = '[Integrations] Close App'

  constructor(public appId: string) {}
}

export class OpenLookup<T> {
  static readonly type = '[Integrations] Open Lookup'

  constructor(public data?: LookupData<T>, public badge: boolean = false) {}
}

export class SetCurrentLookup {
  static readonly type = '[Integrations] Set Current Lookup'

  constructor(public label: string) {}
}

export class CloseLookup {
  static readonly type = '[Integrations] Close Lookup'

  constructor(public label: string) {}
}

export class GoBack {
  static readonly type = '[Integrations] Go Back'

  constructor() {}
}

export class HistoryChange {
  static readonly type = '[Integration] History Change'

  constructor(public appId: string, public state: any) {}
}

export class PushHistory {
  static readonly type = '[Integrations] Push History'

  constructor(public node: IntegrationHistoryNode) {}
}

export class SetState {
  static readonly type = '[Integrations] Set State'

  constructor(public state: IntegrationStateModel) {}
}

export class TogglePanel {
  static readonly type = '[Integrations] Toggle Panel'

  constructor(public value?: boolean) {}
}

export class UpdateAppsPermissions<T> {
  static readonly type = '[Integrations] Update Apps Permissions'

  constructor() {}
}
