export class Forbidden {
  static readonly status = 403
  public readonly status = 403

  constructor(public details?: any) {}
}

export class Unauthenticated {
  static readonly status = 401
  public readonly status = 401
  static readonly error_code = 40001
}

export class TwoFactorRequired {
  static readonly status = 401
  public readonly status = 401
  static readonly error_code = 40101
}

export class NotFound {
  static readonly status = 404
  public readonly status = 404
}

export class Unknown {
  static readonly status = 500
  public status = 500

  constructor(status: number) {
    this.status = status
  }
}

export class MethodNotAllowed {
  static readonly status = 405
  public readonly status = 405
}

export class NotAcceptable {
  static readonly status = 406
  public readonly status = 406
}

export class BadRequest {
  static readonly status = 400
  public readonly status = 400

  constructor(public details: any) {}
}

export function getError(status: number, error: { error_code?: number }) {
  switch (status) {
    case Forbidden.status:
      return new Forbidden()
    case Unauthenticated.status:
      if (error && error.error_code === TwoFactorRequired.error_code) {
        return new TwoFactorRequired()
      }
      return new Unauthenticated()
    case NotFound.status:
      return new NotFound()
    case MethodNotAllowed.status:
      return new MethodNotAllowed()
    case NotAcceptable.status:
      return new NotAcceptable()
    case BadRequest.status:
      return new BadRequest(error)
    default:
      return new Unknown(status)
  }
}
