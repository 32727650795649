import { arrayLengthsMatch, uniqueValuesOf } from './array.functions'

export function textContains(text: string, subtext: string) {
  return text.indexOf(subtext) !== -1
}

export function charactersOf(value: string) {
  return uniqueValuesOf(value.toLowerCase().split(''))
}

export function containsAllCharacters(name: string, letters: string[]) {
  return arrayLengthsMatch(
    letters.filter((letter) => textContains(name, letter)),
    letters
  )
}

export function isNumeric(value: string) {
  return value && value.replace(/[^\d]+/g, '') === value
}
