import { Component } from '@angular/core'

@Component({
  selector: 'status',
  template: `<div [attr.class]="state"></div>`,
  styleUrls: ['status.component.scss'],
  inputs: ['state']
})
export class StatusComponent {
  state: 'online' | 'dnd' | 'offline' = 'offline'
}
