import { Injectable } from '@angular/core'
import {
  JanusAdapter,
  JanusListenerProps,
  RegistrationFailureKind,
  Softphone,
  SoftphoneProps,
  useSoftphone
} from '@cytracom/softphone'
import { DesktopControls } from '@cytracom/softphone/softphone/janus/desktop.controls'
import { TelosThemeProvider } from '@cytracom/telos'
import { Store } from '@ngxs/store'
import React from 'react'
import ReactDOM from 'react-dom'
import { Subject } from 'rxjs'
import { SoundsService } from '../../../common/services/sounds/sounds.service'
import { NotificationService } from '../../notifications/notification.service'
import { SilenceNoise } from '../my-call-status/state/my-call-status.actions'
import { OriginationService } from '../origination/origination.service'
import { ElectronService } from '../../electron/electron.service'
import { JanusServerInfo } from '@cytracom/softphone/softphone/janus/connect/JanusConnector'
import { BackoffGenerator } from '../../common/backoff'
import { JanusService } from './janus.service'

// https://stackoverflow.com/a/59681620
type Timer = ReturnType<typeof setInterval>

@Injectable()
export class JanusSoftphoneAdapter extends JanusAdapter {
  get enabled() {
    return this.isEnabled()
  }

  displayNetworkError = new Subject()
  backoffGenerator = new BackoffGenerator({ fudgeMS: 2500 })

  previouslyFailedRegistration: boolean = false
  sipCredentialFetchTimeout: Timer | null = null

  listeners: JanusListenerProps = {
    onRegistrationFailed: (_line, kind) => {
      if (kind !== RegistrationFailureKind.BAD_SIP_CREDENTIALS) {
        // prevent authentications from retriggering when a register server is down
        console.warn(
          'Will not refetch SIP credentials after a non-SIP credential failure'
        )
        return
      }

      if (this.sipCredentialFetchTimeout !== null) {
        clearTimeout(this.sipCredentialFetchTimeout)
        this.sipCredentialFetchTimeout = null
      }

      this.previouslyFailedRegistration = true
      this.notification.snack(
        'Unable to connect to Cytracom Voice Services. This may be resolved by logging out and back in.'
      )
      this.sipCredentialFetchTimeout = setTimeout(() => {
        // This is a little bit of a tangled mess but I don't think there's a better way to do it.
        // Right now, the JanusComopnent holds a copy of this adapter, both of which call janusService.
        // Upon doing so, the session will get updated, which the component is listening on, and will notify us of
        // the new credentials.
        this.janusService.refreshSIPCredentials()
      }, this.backoffGenerator.nextTimeout())
    },
    onRegistered: () => {
      if (!this.previouslyFailedRegistration) {
        return
      }

      this.previouslyFailedRegistration = false
      this.notification.snack(
        'Connection re-established to Cytracom Voice Services'
      )
    },
    onIncomingCall: (lineId: number, message: any) => {
      console.log('Incoming:', lineId, message)
      const softphoneLine = useSoftphone
        .getState()
        .lines.find((line) => line.lineId === lineId)
      this.notification.webNotification({
        title: softphoneLine?.displayName || 'Unknown Caller',
        body: 'Incoming Call'
      })
      if (this._state.getValue().established.length === 0) {
        this.playRingTone()
      }
    },
    onTransfer: (lineId: number, message: any) => {
      console.log('Transferred:', lineId, message)
      if (this._state.getValue().established.length === 0) {
        this.playRingTone()
      }
    },
    onSlowLink: (lineId: number, state) => {
      this.displayNetworkError.next()
      console.warn('Network Unstable')
    }
  }

  onSilence = () => {
    this.store.dispatch(new SilenceNoise())
    this.sounds.stop('incoming-call')
  }

  onOrigination = (number) => {
    this.origination.originate(number)
  }

  onMerge = (lineId1: number, lineId2: number, errorMsg?: string) => {
    if (errorMsg) {
      this.notification.snack('Merge failed')
      console.warn(errorMsg)
      return
    }

    this.notification.snack('Merge successful')
  }

  onSplit = (lineId: number, errorMsg?: string) => {
    if (errorMsg) {
      this.notification.snack('Split failed')
      console.warn(errorMsg)
      return
    }

    this.notification.snack('Split successful')
  }

  onTransferTo = (destination: number | string, errorMsg?: string) => {
    if (errorMsg) {
      this.notification.snack('Transfer failed')
      console.warn(errorMsg)
      return
    }

    this.notification.snack('Transfer successful')
  }

  changeInput(deviceId) {
    if (deviceId) {
      useSoftphone.getState().updateDeviceId('mic', deviceId)
      localStorage?.setItem('audio.input', deviceId)
      this.micDeviceId = deviceId
    }
  }

  changeOutput(deviceId) {
    if (deviceId) {
      useSoftphone.getState().updateDeviceId('speaker', deviceId)
      localStorage?.setItem('audio.output', deviceId)
      this.speakerDeviceId = deviceId
    }
  }

  constructor(
    private notification: NotificationService,
    private sounds: SoundsService,
    private store: Store,
    private origination: OriginationService,
    private electron: ElectronService,
    private janusService: JanusService
  ) {
    super()
  }

  updateCredentials(servers: JanusServerInfo[]) {
    this.janusServers = servers
    // Must re-render to force the props to update
    this.render()
  }

  childComponent = (props) => {
    return (
      <TelosThemeProvider>
        <Softphone {...props} />
      </TelosThemeProvider>
    )
  }

  render() {
    if (!this.element)
      return console.error('No Container Element for Janus Container')

    this.component = React.createElement(this.childComponent, {
      servers: this.janusServers,
      onChangeCallMode: this.onChangeCallMode,
      onControls: this.onDesktopControls,
      logLevel: this.logLevel || 'error',
      listeners: this.listeners
    })
    ReactDOM.render<SoftphoneProps>(this.component, this.element as HTMLElement)
  }

  onDesktopControls = (controls: DesktopControls) => {
    this.controls = controls
    this.controls.onOrigination = this.onOrigination
    this.controls.onMerge = this.onMerge
    this.controls.onSplit = this.onSplit
    this.controls.onTransferTo = this.onTransferTo
    this.onReady()
  }

  private playRingTone() {
    if (this.electron.isRunningInElectron()) {
      this.electron
        .isOSOnDND()
        .then((result) => {
          if (!result) this.sounds.play('incoming-call')
        })
        .catch((e) => {
          console.error(
            'Failed to check if OS is on DND. Playing ringtone anyway. ' + e
          )

          this.sounds.play('incoming-call')
        })
    } else {
      this.sounds.play('incoming-call')
    }
  }
}
