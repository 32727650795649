/**
 * Generate exponential backoff for a connection.
 *
 * (this could technically be an interface with classes implementing it for
 *  maximum OOP/open closed principle, but this seems like a great instance
 *  of YAGNI)
 */
export interface BackoffOptions {
  fudgeMS?: number
}
export class BackoffGenerator {
  private static readonly BASE_RECONNECT_TIME_MS = 1000
  private static readonly RECONNECT_BACKOFF_FACTOR = 2
  private static readonly MAX_RECONNECT_TIME_MS = 15_000

  private nextReconnectTimeMs: number

  constructor(private options?: BackoffOptions) {
    this.nextReconnectTimeMs =
      BackoffGenerator.BASE_RECONNECT_TIME_MS + this.randomOffset()
  }

  nextTimeout() {
    const reconnectTime = this.nextReconnectTimeMs

    const nextTimeCandidate =
      this.nextReconnectTimeMs * BackoffGenerator.RECONNECT_BACKOFF_FACTOR +
      this.randomOffset()

    this.nextReconnectTimeMs = Math.min(
      BackoffGenerator.MAX_RECONNECT_TIME_MS,
      nextTimeCandidate
    )

    return reconnectTime
  }

  reset() {
    this.nextReconnectTimeMs = BackoffGenerator.BASE_RECONNECT_TIME_MS
  }

  private randomOffset(): number {
    if (this.options?.fudgeMS == null || this.options.fudgeMS <= 0) {
      return 0
    }

    return Math.floor(Math.random() * this.options.fudgeMS)
  }
}
