import { Inject, Injectable, OnDestroy } from '@angular/core'
import { JanusServerInfo } from '@cytracom/softphone/softphone/janus/connect/JanusConnector'
import { Store } from '@ngxs/store'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators'
import lodash from 'lodash'
import { SIPCredentials, SessionData } from 'projects/page/login/session.data'
import { ConnectionManager } from '../../connections/connection.manager'
import { UpdateSIPCredentials } from '../../session/state/session.actions'
import { HttpClient } from '@angular/common/http'

export const ENVIRONMENT_TOKEN = 'janus-environment'

export type SIPCredentialSessionData = Pick<
  SessionData,
  'sip_username' | 'sip_credentials' | 'sip_pw_hash' | 'cytra_secret'
>

export type SIPEnvironmentInformation = {
  standaloneSIP: string
  standaloneRegistrar: string

  activeActiveConnections: Record<
    string,
    { sip: string; registrar: string; priority: number }
  >
}

export enum ExpectedConnectionType {
  STANDALONE,
  ACTIVE_ACTIVE
}

interface SIPCredentialResponse {
  sip_credentials: SIPCredentials[]
}

@Injectable()
export class JanusService implements OnDestroy {
  private unsubscribe: Subject<void>
  private featureFlaggedConnectionMode: BehaviorSubject<ExpectedConnectionType | null>

  constructor(
    private store: Store,
    private connection: ConnectionManager,
    private http: HttpClient,
    @Inject(ENVIRONMENT_TOKEN)
    private env: SIPEnvironmentInformation
  ) {
    this.unsubscribe = new Subject()
    // Default this to null because we want to indicate that we haven't received word of the feature flag yet.
    this.featureFlaggedConnectionMode = new BehaviorSubject(null)

    this.listenForCredentialUpdates()
    this.setupFeatureFlagListener()
  }

  servers$(): Observable<JanusServerInfo[]> {
    return this.store
      .select((session) => session.session)
      .pipe(
        map((session) => this.serverInfoFromSession(session)),
        distinctUntilChanged(lodash.isEqual)
      )
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
  }

  async refreshSIPCredentials() {
    // Having a working phone is critical to the function of the app so we should at least _try_ to fetch
    // them again
    const res = await this.retryNTimes('fetch new credentials', 3, () =>
      this.http
        .get<SIPCredentialResponse>('/v1.0/data/plugin/sip-credentials')
        .toPromise()
    )

    this.store.dispatch(new UpdateSIPCredentials(res.sip_credentials))
  }

  markExpectedConnectionMode(mode: ExpectedConnectionType) {
    this.featureFlaggedConnectionMode.next(mode)
  }

  static buildServerInfo(
    env: SIPEnvironmentInformation,
    credentials: SIPCredentialSessionData
  ) {
    const {
      sip_username: sipUsername,
      sip_pw_hash: sipPwHash,
      cytra_secret: cytraSecret,
      sip_credentials: sipCredentials
    } = credentials

    if (sipCredentials == null) {
      if (cytraSecret) {
        return [
          JanusService.buildStandaloneServerInfo(
            env,
            sipUsername,
            cytraSecret,
            true
          )
        ]
      } else {
        return [
          JanusService.buildStandaloneServerInfo(
            env,
            sipUsername,
            sipPwHash,
            false
          )
        ]
      }
    } else if (sipCredentials.length === 1) {
      const standaloneCredentials = sipCredentials[0]
      return [
        JanusService.buildStandaloneServerInfo(
          env,
          standaloneCredentials.username,
          standaloneCredentials.cytra_secret
            ? standaloneCredentials.cytra_secret
            : standaloneCredentials.pw_hash,
          !!standaloneCredentials.cytra_secret
        )
      ]
    } else {
      return JanusService.buildActiveActiveServerInfo(env, sipCredentials)
    }
  }

  private listenForCredentialUpdates() {
    this.connection.subscribe(
      'sip_auth',
      () => {
        // We don't care about the contents of the event, as all this needs to tell us to do is refresh the creds
        this.refreshSIPCredentials()
      },
      false,
      this.unsubscribe
    )
  }

  private setupFeatureFlagListener() {
    const servers$ = this.servers$()

    this.featureFlaggedConnectionMode
      .pipe(
        takeUntil(this.unsubscribe),
        withLatestFrom(servers$),
        filter(
          ([connectionType, serverInfo]: [
            ExpectedConnectionType | null,
            JanusServerInfo[]
          ]) => {
            // If we haven't gotten indication of what connection type from the feature flag, just assume
            // whatever we have in the session is the right thing to do.
            if (connectionType === null) {
              return false
            }

            if (
              connectionType === ExpectedConnectionType.STANDALONE &&
              serverInfo.length > 1
            ) {
              return true
            } else if (
              connectionType === ExpectedConnectionType.ACTIVE_ACTIVE &&
              serverInfo.length === 1
            ) {
              return true
            } else {
              // Defensively, if there were ever somehow some number of servers we didn't expect (like 3), ignore the
              // update
              console.warn(
                `Unexpected number of servers from SIP credentials: Got ${
                  serverInfo.length
                } in ${
                  connectionType === ExpectedConnectionType.STANDALONE
                    ? 'standalone'
                    : 'active-active'
                } mode`
              )
              return false
            }
          }
        )
      )
      .subscribe(() => {
        this.refreshSIPCredentials()
      })
  }

  private serverInfoFromSession(sessionData: SessionData): JanusServerInfo[] {
    return JanusService.buildServerInfo(this.env, sessionData)
  }

  private static buildStandaloneServerInfo(
    env: SIPEnvironmentInformation,
    username: string,
    password: string,
    useCytraSecret: boolean
  ): JanusServerInfo {
    return {
      connectionName: 'default',
      webRtcUrl: JanusService.buildWebRtcUrl(env.standaloneSIP),
      registrationServer: env.standaloneRegistrar,
      sipUsername: username,
      sipPwHash: password,
      useCytraSecret: useCytraSecret,
      priority: 0
    }
  }

  private static buildActiveActiveServerInfo(
    env: SIPEnvironmentInformation,
    sipCredentials: SIPCredentials[]
  ): JanusServerInfo[] {
    return sipCredentials
      .map((credentialSet) => {
        const connectionInfo = env.activeActiveConnections[credentialSet.name]
        if (connectionInfo == null) {
          console.warn(
            `Got connection info for server ${credentialSet.name}, but was not configured in environment`
          )
          return null
        }

        return {
          connectionName: credentialSet.name,
          sipUsername: credentialSet.username,
          sipPwHash: credentialSet.cytra_secret
            ? credentialSet.cytra_secret
            : credentialSet.pw_hash,
          useCytraSecret: !!credentialSet.cytra_secret,
          registrationServer: connectionInfo.registrar,
          webRtcUrl: this.buildWebRtcUrl(connectionInfo.sip),
          priority: connectionInfo.priority
        }
      })
      .filter((serverInfo) => serverInfo != null)
  }

  private static buildWebRtcUrl(hostname: string): string {
    return `wss://${hostname}/janus`
  }

  private async retryNTimes<T>(
    operationName: string,
    numTimes: number,
    func: () => Promise<T>
  ): Promise<T> {
    for (let i = 0; i < numTimes; i++) {
      try {
        return await func()
      } catch (err) {
        if (i < numTimes - 1) {
          console.warn(
            // prettier-ignore
            `Failed to run ${operationName}: ${err} (Attempt ${i + 1} / ${numTimes})`
          )
        } else {
          throw err
        }
      }
    }
  }
}
