import { BridgedElectronWindow } from '../electron.types'
import { ElectronStrategy } from '../strategy'

declare let window: BridgedElectronWindow

export class BridgedStrategy implements ElectronStrategy {
  isOSOnDND(): Promise<boolean> {
    console.log('using bridged strategy')
    return window.electronBridge.isOSOnDND()
  }

  updateBadge(count: number): void {
    return window.electronBridge.updateBadge(count)
  }

  sendNotification(
    instance: number,
    config: { title: string } & NotificationOptions
  ): Promise<string> {
    return window.electronBridge.sendNotification(instance, config)
  }

  on(event: string, callback: (...args: any[]) => void): () => void {
    return window.electronBridge.on(event, callback)
  }

  getPlatform(): string {
    return window.electronBridge.getPlatform()
  }

  openWindowsMicPage(): void {
    return window.electronBridge.openWindowsMicPage()
  }
}
