import { Pipe, PipeTransform } from '@angular/core'
import { UserStatus } from './status.pipe'

/**
 * Converts the status of a user (from StatusPipe) to a user-readable label
 */
@Pipe({
  name: 'status_label'
})
export class StatusLabelPipe implements PipeTransform {
  constructor() {}
  transform(status: UserStatus): string {
    switch (status) {
      case 'online':
        return 'Online'
      case 'dnd':
        return 'Do Not Disturb'
      case 'offline':
      default:
        return 'Away'
    }
  }
}
