import { Action, Selector, State, StateContext } from '@ngxs/store'
import { SessionData } from '../../../page/login/session.data'
import {
  DestroySession,
  UpdateSIPCredentials,
  StoreSession,
  UpdateAvatar,
  UpdateDids
} from './session.actions'
import { Router } from '@angular/router'
import { Injectable, NgZone } from '@angular/core'
import { SetPermission } from './permission.actions'
import { Permission } from './permission.constants'
import { useApiConfig } from '@cytracom/desktop'
import { environment } from '../../../../src/environments/environment'

@State<SessionData>({
  name: 'session',
  defaults: null
})
@Injectable()
export class SessionState {
  @Selector()
  static loginId(state: SessionData) {
    return state.login_id
  }

  constructor(private router: Router, private zone: NgZone) {}

  @Action(StoreSession)
  async onStore(context: StateContext<SessionData>, action: StoreSession) {
    const previous = context.getState()
    delete action.data.sip_password
    action.data.messaging_numbers = []
    action.data.shared_numbers = []
    action.data.default_did = ''
    context.setState(action.data)
    if (action.data.documo_user_enabled) {
      context.dispatch(new SetPermission(Permission.FAX, true))
    }
    context.dispatch(new SetPermission(Permission.CALL_CENTER_DASHBOARD, true))
    if (action.data.plan === 'c2' || action.data.plan === 'demo') {
      context.dispatch(new SetPermission(Permission.MESSAGING, true))
    }
    const { update } = useApiConfig.getState()
    update('token', action.data.app_token)
    update('contactBaseUrl', environment.contactBaseUrl + '/apps/api')
    if (!previous && action.data) {
      await this.zone.run(async () => {
        await this.router.navigate(['/app'])
      })
    }
  }

  @Action(UpdateSIPCredentials)
  onUpdateSIPCredentials(
    context: StateContext<SessionData>,
    action: UpdateSIPCredentials
  ) {
    if (action.newCredentials == null) {
      console.warn(
        'Got a request to set SIP credentials to null, refusing to do so'
      )
      return
    } else if (action.newCredentials.length === 0) {
      console.warn(
        'Got a request to remove all SIP credentials, refusing to do so'
      )
      return
    }

    context.patchState({ sip_credentials: action.newCredentials })
  }

  @Action(UpdateDids)
  updateDids(context: StateContext<SessionData>, action: UpdateDids) {
    context.patchState(action.data)
    context.dispatch(
      new SetPermission(
        Permission.MESSAGING_SMS,
        action.data.messaging_numbers.length > 0
      )
    )
  }

  @Action(DestroySession)
  async onDestroy(context: StateContext<SessionData>, action: DestroySession) {
    context.setState(null)
    // TODO: Remove this once new backend service has been created
    localStorage.removeItem('ringtoneKey')
    useApiConfig.getState().update('token', '')
    localStorage.removeItem('newCalls')
    await this.zone.run(async () => {
      await this.router.navigate(['/login'])
    })
  }

  @Action(UpdateAvatar)
  updateAvatar(context: StateContext<SessionData>, action: UpdateAvatar) {
    context.patchState({
      avatar_url: action.avatar
    })
  }
}
