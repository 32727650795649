import {
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core'

@Component({
  selector: 'empty-state',
  templateUrl: 'empty-state.component.html',
  styleUrls: ['empty-state.component.scss'],
  inputs: ['message', 'hasMessageIcon', 'searchTerm', 'type'],
  encapsulation: ViewEncapsulation.None
})
export class EmptyStateComponent {
  @ViewChild('messageElement', { static: true })
  messageElement: ElementRef<HTMLElement>

  searchTerm: string
  type: string
  message: string
}
