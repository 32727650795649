import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'
import { first } from 'rxjs/operators'
import { NotificationService } from '../notifications/notification.service'

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  static updateAvailable = new BehaviorSubject(false)

  constructor(private notification: NotificationService) {}

  notify() {
    this.notification
      .notification('Update Available', 'A new version is available', {
        disableTimeOut: true,
        tapToDismiss: false,
        icon: 'sort-up',
        iconColor: '#7AC420',
        actions: [
          {
            action: 'update',
            label: 'Update'
          },
          {
            action: 'ignore',
            label: 'Not Now'
          }
        ]
      })
      .onAction.pipe(first())
      .subscribe((result) => {
        if (result === 'update') {
          window.location.reload(true)
        }
      })
    this.notification
      .webNotification({
        title: 'Update Available',
        body: 'A new version is available',
        actions: [
          {
            action: 'update',
            title: 'Update'
          },
          {
            action: 'ignore',
            title: 'Not Now'
          }
        ]
      })
      .then((result) => {
        console.log(result)
        if (result === 'click') {
          window.focus()
        }
        if (result === 'update') {
          window.focus()
          window.location.reload(true)
        }
      })
  }
}
