import { Injectable } from '@angular/core'
import { fromEvent } from 'rxjs'
import { filter, first, map } from 'rxjs/operators'
import { timeout } from '../../common/functions/timeout'
import { VisibilityService } from '../../common/services/visibility.service'
import { ElectronService } from '../electron/electron.service'

export const NOTIFICATION_IGNORED_STATUS = 'ignored'

@Injectable()
export class WebNotificationService {
  instance = 0

  constructor(
    private electron: ElectronService,
    private visibility: VisibilityService
  ) {}

  canRequestPermission() {
    return 'serviceWorker' in navigator && typeof Notification !== 'undefined'
  }

  async requestPermission() {
    console.info('Web Notifications are ' + (await this.getPermission()))
  }

  isEnabled() {
    return Notification.permission === 'granted'
  }

  async send(config: { title: string } & NotificationOptions): Promise<string> {
    if (this.isEnabled() && !this.visibility.visible.getValue()) {
      if (this.electron.isRunningInElectron()) {
        return await this.sendIPCNotification(config)
      }
      return await this.sendServiceWorkerNotification(config)
    }
    return NOTIFICATION_IGNORED_STATUS
  }

  sendIPCNotification(config: { title: string } & NotificationOptions) {
    const instance = ++this.instance
    return new Promise<string>((resolve) => {
      this.electron.sendNotification(instance, config)
    })
  }

  async sendServiceWorkerNotification(
    config: { title: string } & NotificationOptions
  ) {
    const worker = await this.getNotificationWorker()
    if (worker) {
      const instance = ++this.instance
      await worker.showNotification(config.title, {
        tag: instance + '',
        body: config.body
      })
      return await timeout(
        this.onNotificationAction(instance),
        6 * 1000,
        NOTIFICATION_IGNORED_STATUS
      )
        .pipe(first())
        .toPromise()
    }
    return NOTIFICATION_IGNORED_STATUS
  }

  private async getPermission() {
    if (this.canRequestPermission()) {
      await navigator.serviceWorker.ready
      const status = await Notification.requestPermission()
      return status === 'granted' ? 'enabled' : 'not enabled'
    }
    return 'not supported'
  }

  private async getNotificationWorker() {
    await navigator.serviceWorker.ready
    return await navigator.serviceWorker.getRegistration('/cytracom-worker.js')
  }

  private onNotificationAction(instance) {
    return fromEvent(navigator.serviceWorker, 'message').pipe(
      map((event: MessageEvent) => event.data),
      filter((event) => event.event === 'notification-' + instance + '-reply'),
      map((event) => event.data),
      first()
    )
  }
}
