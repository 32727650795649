import { ChangeDetectorRef } from '@angular/core'

export class PushComponent<T> {
  protected _state: T

  constructor(protected changes: ChangeDetectorRef, initialState: T) {
    this.setState(initialState)
  }

  setState(value: T) {
    this._state = value
    Object.freeze(this._state)
    this.changes.markForCheck()
  }

  patchState<T>(value: Partial<T>) {
    this.setState({
      ...this._state,
      ...value
    })
  }

  get state(): T {
    return this._state
  }

  markForCheck() {
    return this.changes.markForCheck()
  }
}
