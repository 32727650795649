import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { IconComponent } from './icon.component'
import { IconsComponent } from './icons.component'

@NgModule({
  imports: [],
  declarations: [IconComponent, IconsComponent],
  exports: [IconComponent, IconsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IconsModule {}
