import { Component, HostListener, ViewEncapsulation } from '@angular/core'
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations'
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr'
import { ToastConfig } from './toast-config'

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0)',
              opacity: 0
            }),
            style({
              transform: 'none',
              opacity: 1
            })
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1
            }),
            style({
              transform: 'translate3d(100%, 0, 0)',
              opacity: 0
            })
          ])
        )
      )
    ])
  ]
})
export class NotificationComponent extends Toast {
  options: ToastConfig<NotificationComponent>

  @HostListener('click', ['$event'])
  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  @HostListener('blur', ['$event'])
  @HostListener('mouseout', ['$event'])
  @HostListener('mouseover', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('mouseup', ['$event'])
  onEvents(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage)
  }

  onAction(event: Event, action: string) {
    event.stopPropagation()
    this.toastPackage.triggerAction(action)
    this.remove()
  }
}
