import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { DatadogService } from 'projects/system/datadog/datadog.service'
import { WakeService } from '../../projects/system/wake/wake.service'

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppRootComponent implements OnInit {
  showOverlay = false

  constructor(private wake: WakeService, private datadog: DatadogService) {
    window.addEventListener('online', () => {
      this.showOverlay = false
    })
    window.addEventListener('offline', () => {
      this.showOverlay = true
    })
  }

  ngOnInit() {
    this.datadog.startDatadog()
  }
}
