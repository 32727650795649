import {
  COUNTRY_CODE_PHONE_FORMAT,
  PHONE_FORMAT,
  SHORT_CODE_OR_EMERGENCY_FORMAT
} from './format.constants'

export function formatPhoneNumber(phoneNumberString: number | string): string {
  let formatted = ''
  const number = sanitizePhoneNumber('' + phoneNumberString)
  const numberPartsWithoutCountryCode = getMatchesOfPattern(
    number,
    PHONE_FORMAT
  )
  const numberPartsWithCountryCode = getMatchesOfPattern(
    number,
    COUNTRY_CODE_PHONE_FORMAT
  )

  const numberPartsForShortCodeOrEmergency = getMatchesOfPattern(
    number,
    SHORT_CODE_OR_EMERGENCY_FORMAT
  )
  if (numberPartsWithCountryCode.length > 0) {
    formatted = formatPhoneNumberWithCountryCode(numberPartsWithCountryCode)
  }
  if (numberPartsWithoutCountryCode.length > 0) {
    formatted = formatPhoneNumberWithAreaCode(numberPartsWithoutCountryCode)
  }
  if (numberPartsForShortCodeOrEmergency.length > 0) {
    formatted = formatShortCodeOrEmergency(
      numberPartsForShortCodeOrEmergency[0]
    )
  }
  return formatted
}

export function getMatchesOfPattern(value: string, format: string | RegExp) {
  const match: RegExpMatchArray = value.match(format)
  if (match) {
    match.shift()
    return [...match]
  }
  return []
}

export function sanitizePhoneNumber(value: string) {
  let split = ('' + value).split('ext')
  split = split[0].split('#')
  split = split[0].split('x')
  return removeNonDigits(split[0])
}

export function removeNonDigits(value: string) {
  return value.replace(/[^\d]/g, '')
}

export function formatPhoneNumberWithCountryCode(
  numberSegments: (string | number)[]
) {
  return (
    '+1 (' +
    numberSegments[0] +
    ') ' +
    numberSegments[1] +
    '-' +
    numberSegments[2]
  )
}

export function formatPhoneNumberWithAreaCode(
  numberSegments: (string | number)[]
) {
  return (
    '(' + numberSegments[0] + ') ' + numberSegments[1] + '-' + numberSegments[2]
  )
}

export const formatShortCodeOrEmergency = (number: string) => {
  if (number === '911' || number === '988')
    return number === '911' ? '911 Emergency Services' : '988 Crisis Hotline'

  return number
}
