import { MyCallStatuses } from './my-call-statuses'

export class MyCallStatusModel {
  currentUniqueId: string = ''

  calls: {
    [uniqueId: string]: MyCall
  }
}

export interface MyCall {
  number: string
  name: string
  direction: 'inbound' | 'outbound'
  status: MyCallStatuses
  start?: number
  startString?: string
}
