/**
 * Represents the state of presence as sent by evhub. This could be either DND or Online.
 */
export interface InitialPresenceState {
  users: string[]
}

/**
 * Represents the state of presence as we know it to be.
 */
export class PresenceModel {
  users: Record<string, PresenceUser> = {}
}

/**
 * Represents a single user's presence.
 */
export class PresenceUser {
  online: boolean = false
  do_not_disturb: boolean = false
}

export class PresenceUpdateOnlineModel {
  login_id: string
  status: 'online' | 'offline'
}

export class PresenceUpdateDNDModel {
  data: PresenceUpdateDNDModelData
  status: 'enabled' | 'disabled'
}

export class PresenceUpdateDNDModelData {
  login_id: string
}
