import {
  AfterViewInit,
  Directive,
  EventEmitter,
  OnDestroy
} from '@angular/core'
import { ConnectionManager } from './connection.manager'
import { Observable } from 'rxjs'
import { SessionData } from '../../page/login/session.data'
import { Select, Store } from '@ngxs/store'
import { SessionState } from '../session/state/session.state'
import { filter, takeUntil } from 'rxjs/operators'
import { LoginService } from '../../page/login/login.service'
import { DestroySession } from '../session/state/session.actions'

@Directive({
  selector: '[connect]'
})
export class ConnectDirective implements AfterViewInit, OnDestroy {
  @Select(SessionState)
  session: Observable<SessionData>

  destroy = new EventEmitter()

  constructor(
    private connection: ConnectionManager,
    private loginService: LoginService,
    private store: Store
  ) {}

  ngAfterViewInit() {
    let lastToken = null

    this.session
      .pipe(takeUntil(this.destroy))
      .pipe(filter((session) => session?.app_token !== lastToken))
      .subscribe(async (session) => {
        if (lastToken) this.connection.disconnect()

        if (session?.app_token) {
          const isValidToken = await this.loginService.checkToken(
            session.app_token
          )
          if (isValidToken) {
            this.connection.connect(session)
          } else {
            this.store.dispatch(new DestroySession())
          }
        }

        lastToken = session?.app_token || ''
      })
  }

  ngOnDestroy() {
    this.destroy.emit()
    this.connection.disconnect()
  }
}
