import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { SessionModule } from '../session/session.module'
import { ConnectDirective } from './connect.directive'
import { LoginService } from '../../page/login/login.service'

@NgModule({
  imports: [NgxsModule, SessionModule],
  providers: [LoginService],
  declarations: [ConnectDirective],
  exports: [ConnectDirective]
})
export class ConnectionsModule {}
