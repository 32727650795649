import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  ExpectedConnectionType,
  JanusService
} from '../../system/phone/softphone/janus.service'

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(private http: HttpClient, private janusService: JanusService) {}

  MUTE_SHARED_INBOX = false
  NEW_CONTACTS = false
  PENDO_INITIALIZATION = true
  ACTIVE_ACTIVE = false

  async initialize() {
    this.MUTE_SHARED_INBOX = false
    this.NEW_CONTACTS = false
    this.PENDO_INITIALIZATION = true
    this.ACTIVE_ACTIVE = false

    const featureFlags = await this.getFeatureFlags()
    for (const flag of featureFlags) {
      switch (flag.constraint) {
        case 'MUTE_SHARED_INBOX':
          this.MUTE_SHARED_INBOX = flag.is_active === 1
          break
        case 'NEW_CONTACTS':
          this.NEW_CONTACTS = flag.is_active === 1
          break
        case 'PENDO_INITIALIZATION':
          this.PENDO_INITIALIZATION = flag.is_active === 1
          break
        case 'DESKTOP_ACTIVE_ACTIVE':
          this.ACTIVE_ACTIVE = flag.is_active === 1
          break
      }
    }

    // Explicitly send a signal about active-active, so that if the flag isn't present,
    // we still pick the new setting
    this.janusService.markExpectedConnectionMode(
      this.ACTIVE_ACTIVE
        ? ExpectedConnectionType.ACTIVE_ACTIVE
        : ExpectedConnectionType.STANDALONE
    )
  }

  async getFeatureFlags() {
    return await this.http
      .get<{ name: string; constraint: string; is_active: 0 | 1 }[]>(
        `/v1.0/feature/settings/feature/customer`
      )
      .toPromise()
  }
}
