import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { MyCallStatusState } from '../my-call-status/state/my-call-status.state'
import { HttpClient } from '@angular/common/http'
import {
  OriginatingCall,
  TransferredCall
} from '../my-call-status/state/my-call-status.actions'
import { PhonePipe } from '../../../common/pipes/phone.pipe'

@Injectable({
  providedIn: 'root'
})
export class OriginationService {
  callPath = '/v2.0/action/chan/originate/{token}'
  transferPath = '/v2.0/action/chan/blind-transfer/{token}'
  queueTransferPath = '/v2.0/action/chan/queue-transfer/{token}'

  phone = new PhonePipe()

  constructor(private store: Store, private http: HttpClient) {}

  async originate(number: string, stripDigits: boolean = true) {
    const channelId =
      this.store.selectSnapshot(MyCallStatusState).currentChannelId
    let toNumber = number
    if (stripDigits) {
      toNumber = ('' + number).replace(/[^\d*]/g, '')
    }
    if (channelId) {
      await this.transfer(channelId, toNumber)
      return
    }
    this.store.dispatch(
      new OriginatingCall(
        this.phone.transform(toNumber),
        toNumber,
        'origination'
      )
    )
    await this.http
      .post(this.callPath, {
        number: toNumber
      })
      .toPromise()
  }

  private async transfer(channelId: string, number: string) {
    if (number.indexOf('queue_') !== -1) {
      await this.http
        .post(this.queueTransferPath, {
          channel_id: channelId,
          queue_id: ('' + number).replace(/[^\d*]/g, '')
        })
        .toPromise()
      this.store.dispatch(new TransferredCall(name, number, channelId))
      return
    }
    await this.http
      .post(this.transferPath, {
        channel_id: channelId,
        to_number: number
      })
      .toPromise()
    this.store.dispatch(new TransferredCall(name, number, channelId))
  }
}
