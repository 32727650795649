import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { IntegrationApi } from './integration.api'
import { ApiModule } from '../../system/api/api.module'

@NgModule({
  imports: [ApiModule, HttpClientModule],
  providers: [IntegrationApi]
})
export class IntegrationApiModule {}
