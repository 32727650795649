import { Component, HostListener, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'

@Component({
  selector: 'snack-bar',
  templateUrl: 'snack-bar.component.html',
  styleUrls: ['snack-bar.component.scss']
})
export class SnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      dismissOnClick?: boolean
      message: string
    },
    private ref: MatSnackBarRef<SnackBarComponent>
  ) {}

  @HostListener('click')
  onClick() {
    if (this.data.dismissOnClick) {
      this.ref.dismiss()
    }
  }
}
