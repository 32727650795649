const env = window['env'] || {}
const isDev = location.hostname.indexOf('dev-') !== -1

export const environment = {
  beta: 'beta' in env ? env.beta : false,
  production: isDev ? false : 'production' in env ? env.production : true,
  hmr: false,
  appId: '433232',
  canBeMobile: false,
  sse: env.sse || 'https://' + location.hostname,
  websocket: env.websocket || 'wss://' + location.hostname,
  backend: env.backend || 'https://' + location.hostname,
  sip: env.sip || (isDev ? 'dev-' : '') + 'webrtc.cytracom.net',
  register:
    env.register || 'register.' + (isDev ? 'dev.' : '') + 'cytracom.net',
  activeActiveRegisters: {
    dfw: 'register.cytracom.net',
    kwd: 'kr1.cytracom.net'
  },
  integration: env.integration || 'https://beta-desktop.cytracom.net',
  ihub: env.ihub || 'https://' + (isDev ? 'dev-' : '') + 'ihub.cytracom.net',
  contactBaseUrl: isDev
    ? 'https://dev-contacts.cytracom.net'
    : 'https://ct.cytracom.net',
  contactsAppId: isDev
    ? '26fa1b76-8334-42ef-adfb-681eb2a36e90'
    : '40a8a022-fc37-456d-8862-3de5e056b179',
  messaging: '',
  datadog: {
    appId: '928545db-152e-4db8-b877-1d461c6fcc02',
    clientToken: 'pub422dbe66321f13515ae1dce21ce573f5',
    site: 'datadoghq.com'
  },
  activeActiveConnections: isDev
    ? {
        register1: {
          registrar: 'register.dev.cytracom.net',
          sip: 'dev-webrtc.cytracom.net',
          priority: 10
        },
        register2: {
          registrar: 'register2.dev.cytracom.net',
          sip: 'dev-webrtc2.cytracom.net',
          priority: 20
        }
      }
    : {
        dfw: {
          registrar: 'register.cytracom.net',
          sip: 'webrtc3.cytracom.net',
          priority: 10
        },
        kwd: {
          registrar: 'kr1.cytracom.net',
          sip: 'webrtc2.cytracom.net',
          priority: 20
        }
      }
}
