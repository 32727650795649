import { EventEmitter, Injectable } from '@angular/core'
import { ConnectionManager } from '../connections/connection.manager'
import { Store } from '@ngxs/store'
import { SessionState } from '../session/state/session.state'
import { SessionData } from '../../page/login/session.data'
import { tracking } from '../analytics/tracking'

@Injectable()
export class WakeService {
  onWake = new EventEmitter()

  constructor(private connection: ConnectionManager, private store: Store) {
    let token = this.getToken()

    const wakeUp = () => {
      this.onWake.emit()
      tracking.track('wake-up')
    }

    const handleWakeEvent = () => {
      const newToken = this.getToken()
      const connected = this.connection.connected.getValue()
      if (newToken === token) {
        if (connected) {
          wakeUp()
        } else {
          const sub = this.connection.connected.subscribe((value) => {
            if (value) {
              wakeUp()
              sub.unsubscribe()
            }
          })
        }
      }

      token = newToken
    }

    this.connection.subscribe('online', () => setTimeout(handleWakeEvent, 300))

    // navigator.serviceWorker may be undefined when browsing in private mode
    try {
      navigator.serviceWorker.addEventListener('message', (message) => {
        if (message.data === 'wake') {
          handleWakeEvent()
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  private getToken() {
    return this.store.selectSnapshot<SessionData>(SessionState)?.app_token || ''
  }
}
