import { Component, HostBinding, ViewEncapsulation } from '@angular/core'
@Component({
  selector: 'icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
  inputs: ['name', 'size'],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent {
  @HostBinding('attr.name')
  name: string = ''

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  size: number = 20
}
